/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontVelanhoitomeno } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiMenoTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import { AsiointiLedgerAccountSubCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const VelanhoitomenotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const tilirivit = getTilirivit()

  const mkNewElement = (): FrontVelanhoitomeno =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('velanhoitomenot-form')}>
      <Heading variant="h3" mb="xs">
        {t('velanhoitomenotHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaVelanhoitomenot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={VelanhoitomenoListItem}
        state={tilirivit.menot.velanhoitomenot}
        dataTestIdPrefix={mkTulotMenotTestId('velanhoitomenot-form')}
        ModalContentComponent={VelanhoitomenoModal}
        newEntry={mkNewElement}
        translationPrefix="velanhoitomenot"
        tiliLomakeKentta={TiliLomakeKentta.velanhoitomenot}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const VelanhoitomenoModal: FC<FormModalProps<FrontVelanhoitomeno>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const velanhoitomenotyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiMenoTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.VELANHOITOMENOT
            )
          ).map((value) => ({
            name: t(`expenseType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('velanhoitomenonTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={velanhoitomenotyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountExpenseType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormNumberInput
          data-test-id={createDataTestId('modal-menotYhteensa')}
          labelText={t('menotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const VelanhoitomenoListItem: FC<FormElementProps<FrontVelanhoitomeno>> =
  observer(
    ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
      const [t] = useTranslation()
      const isTablet = useDeviceContext().tablet
      const verticalMarginToken = isTablet ? 'm' : 's'

      const yhteensaEur =
        entry.yhteensa !== undefined
          ? `${formatNumberWithComma(entry.yhteensa)} €`
          : undefined

      return (
        <FormListInputExpander
          heading={t(`expenseType_${entry.tyyppi}`)}
          headingRight={yhteensaEur}
          createDataTestId={createDataTestId}
          onEdit={onEdit}
          onRemove={onRemove}
          pakollinenTietoPuuttuu={entry.yhteensa === undefined}
          tiliLomakeKentta={tiliLomakeKentta}
        >
          <ContentItemOrPakollinenTietoPuuttuuWarning
            heading={t('menotTilikaudenAikanaYhteensaEuroina')}
            content={yhteensaEur}
          />
          <Block mt={verticalMarginToken} />
        </FormListInputExpander>
      )
    }
  )

export default VelanhoitomenotForm
