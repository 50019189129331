import {
  Block,
  Heading,
  IconWarning,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import React, { createContext, PropsWithChildren, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import { action, observable } from 'mobx'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import EsitaytetytTiedotOhjeAlert from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/EsitaytetytTiedotAlert'
import { TiliLomakeKenttaId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'
import styled from 'styled-components'

export const PuuttuvatEsitaytetytTiedot = createContext<
  Record<string, TiliLomakeKenttaId | undefined>
>(observable({}))

export const VahvistaEsitaytetytTiedot: React.FC<
  PropsWithChildren & {
    tiedot: TiliLomakeKenttaId[]
    dataTestId: string
  }
> = observer(({ children, tiedot, dataTestId }) => {
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const [t] = useTranslation()

  const [puuttuvatTiedot] = useState(observable({}))

  const {
    lomake: { tarkistetutEsitaytetytTiedot },
  } = getTilintarkastusStore()
  const pageContainsValuneetTiedot = tiedot.some(
    (key) => key in tarkistetutEsitaytetytTiedot
  )
  if (!pageContainsValuneetTiedot) {
    return children
  }

  const tiedotVahvistettu = tiedot.every(
    (t) => tarkistetutEsitaytetytTiedot[t] === true
  )

  const disabled = !!Object.values(puuttuvatTiedot).filter((item) => item)
    .length

  return (
    <PuuttuvatEsitaytetytTiedot.Provider value={puuttuvatTiedot}>
      <Block>
        {pageContainsValuneetTiedot && (
          <EsitaytetytTiedotOhjeAlert dataTestId={dataTestId} />
        )}
        {children}
        <Heading variant="h3" mb="s">
          {t('esitayttettyjenTietojenTarkastus')}
        </Heading>
        <FormCheckboxInput
          data-test-id={dataTestId}
          labelText={t('olenTarkastanutTiedot')}
          requiredLabel={t('valitseTiedotTarkastettu')}
          updateValue={action((value) => {
            tiedot.forEach(
              (form) => (tarkistetutEsitaytetytTiedot[form] = value)
            )
          })}
          defaultValue={tiedotVahvistettu}
          disabled={disabled}
          required
        />
        {disabled && (
          <Block mt="s">
            <TaydennaAlert color={suomifiDesignTokens.colors.accentBase} />
            <Text color="blackBase"> {t('lisaaPuuttuvatTiedot')}</Text>
          </Block>
        )}
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
      </Block>
    </PuuttuvatEsitaytetytTiedot.Provider>
  )
})

export default VahvistaEsitaytetytTiedot

const TaydennaAlert = styled(IconWarning)`
  display: inline-block;
  vertical-align: -3px;
`
