import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  getAddressAsArray,
  getPaivamaaraString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { AsiointiHabitationType } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { Heading } from 'suomifi-ui-components'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getAsuinpaikatContent = (
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      tili: { asuminen },
    },
  } = getTilintarkastusStore()
  return asuminen.asuinpaikat.map(
    ({
      streetAddress,
      streetAddressExtra,
      postalCode,
      postOffice,
      countryCode,
      habitationType,
      habitationPeriodType,
      beginDate,
      endDate,
    }): YhteenvetoGroupProps => {
      const fullDetails =
        habitationType !== AsiointiHabitationType.HOUSELESS &&
        habitationType !== AsiointiHabitationType.UNKNOWN
      return {
        heading: t(`habitationType_${habitationType}`),
        rows: [
          fullDetails
            ? getAddressAsArray(
                {
                  streetAddress,
                  streetAddressExtra,
                  postalCode,
                  postOffice,
                  countryId: countryCode,
                },
                lang
              ).join(', ') || ''
            : '',
          `${
            fullDetails && habitationPeriodType
              ? t(`habitationPeriodType_${habitationPeriodType}`)
              : ''
          }${getPaivamaaraString(t, beginDate, endDate)}`,
        ],
      }
    }
  )
}

const getHoitoJaPalvelusuunnitelmaContent = (
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      lomakkeelleValitutTiedot,
      tili: { asuminen },
    },
  } = getTilintarkastusStore()
  const content: YhteenvetoGroupProps[] = [
    {
      heading: t('hoitoJaPalvelusuunnitelmaHeading'),
      rows: [
        lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma !== undefined
          ? lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
      ...(lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma && {
        attachments: asuminen.hoitoPalveluSuunnitelma,
      }),
    },
  ]
  return content
}

const getAlaikaisenAsuminen = (
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      tili: { asuminen },
      lomakkeelleValitutTiedot,
    },
  } = getTilintarkastusStore()
  const groups: YhteenvetoGroupProps[] = [
    {
      heading: t('alaikainenAsuinpaikkaOhje'),
      rows: [
        lomakkeelleValitutTiedot.huoltajalla !== undefined
          ? lomakkeelleValitutTiedot.huoltajalla
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (lomakkeelleValitutTiedot.huoltajalla) {
    groups.push({
      heading: t('kenenKanssaHenkiloAsuu'),
      rows: [asuminen.huoltajalla],
    })
  }

  if (!lomakkeelleValitutTiedot.huoltajalla) {
    const asuinpaikat = getAsuinpaikatContent(t, lang)
    if (asuinpaikat.length) {
      groups.push(...asuinpaikat)
    } else {
      groups.push({ heading: t('asuinpaikka'), rows: [] })
    }
  }
  return groups
}

const Asuminen: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()
  const lang = useAsiointiUserStore().lang
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('asuminen-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('asuminenHeading')}
      </Heading>

      {isPrincipalUnderaged ? (
        <YhteenvetoGroups groups={getAlaikaisenAsuminen(t, lang)} />
      ) : (
        <>
          <YhteenvetoGroups
            requireRowsOrAttachments={false}
            groups={getAsuinpaikatContent(t, lang)}
          />

          <YhteenvetoGroups groups={getHoitoJaPalvelusuunnitelmaContent(t)} />
        </>
      )}
    </section>
  )
})

export default Asuminen
