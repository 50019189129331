import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export type AsiointiLedgerAccountIncomeType = Extract<
  AsiointiLedgerAccountType,
  | AsiointiLedgerAccountType.TYOELAKE
  | AsiointiLedgerAccountType.KANSANELAKE
  | AsiointiLedgerAccountType.ULKOMAAN_ELAKE
  | AsiointiLedgerAccountType.PERHE_ELAKE
  | AsiointiLedgerAccountType.TAKUUELAKE
  | AsiointiLedgerAccountType.PALKAT
  | AsiointiLedgerAccountType.PALKKIOT
  | AsiointiLedgerAccountType.TYOOSUUSRAHAT
  | AsiointiLedgerAccountType.OMAISHOIDON_TUKI
  | AsiointiLedgerAccountType.ELAKKEENSAAJAN_ASUMISTUKI
  | AsiointiLedgerAccountType.YLEINEN_ASUMISTUKI
  | AsiointiLedgerAccountType.VAMMAISTUKI
  | AsiointiLedgerAccountType.KELAN_KUNTOUTUSRAHA
  | AsiointiLedgerAccountType.KUNTOUTUSTUKI
  | AsiointiLedgerAccountType.SAIRAUSPAIVARAHA
  | AsiointiLedgerAccountType.TOIMEENTULOTUKI
  | AsiointiLedgerAccountType.KOTIHOIDON_TUKI
  | AsiointiLedgerAccountType.ELATUSAPU
  | AsiointiLedgerAccountType.ELATUSTUKI
  | AsiointiLedgerAccountType.VANHEMPAINRAHA
  | AsiointiLedgerAccountType.LAPSILISA
  | AsiointiLedgerAccountType.OPINTOTUKI
  | AsiointiLedgerAccountType.ULKOMAAN_ETUUS
  | AsiointiLedgerAccountType.MUU_ETUUS
  | AsiointiLedgerAccountType.TYOMARKKINATUKI
  | AsiointiLedgerAccountType.PERUSPAIVARAHA
  | AsiointiLedgerAccountType.ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA
  | AsiointiLedgerAccountType.KOTOUTUMISTUKI_MAAHANMUUTTAJALLE
  | AsiointiLedgerAccountType.MUU_TYOTTOMYYSTURVAETUUS
  | AsiointiLedgerAccountType.KELAN_LAAKEKORVAUKSET
  | AsiointiLedgerAccountType.LAAKEKORVAUKSET_MUU
  | AsiointiLedgerAccountType.SAIRASVAKUUTUSKORVAUKSET
  | AsiointiLedgerAccountType.HENKIVAKUUTUSKORVAUKSET
  | AsiointiLedgerAccountType.VAHINKOVAKUUTUSKORVAUKSET
  | AsiointiLedgerAccountType.VAHINGONKORVAUKSET
  | AsiointiLedgerAccountType.MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET
  | AsiointiLedgerAccountType.MAKSUN_PALAUTUKSET
  | AsiointiLedgerAccountType.VERONPALAUTUKSET
  | AsiointiLedgerAccountType.KATEISVAROJA_HALTUUN
  | AsiointiLedgerAccountType.LAHJAT_PERINNOT_TASINKO
  | AsiointiLedgerAccountType.MUUT_TULOT
  | AsiointiLedgerAccountType.VIRHETULO_KORJATAAN_MYOHEMMIN
  | AsiointiLedgerAccountType.VIRHEMAKSUN_KORJAUS
  | AsiointiLedgerAccountType.VUOKRATULOT
  | AsiointiLedgerAccountType.OSINKOTULOT
  | AsiointiLedgerAccountType.KORKOTULOT
  | AsiointiLedgerAccountType.METSANMYYNTI
  | AsiointiLedgerAccountType.IRTAIMEN_MYYNTI
  | AsiointiLedgerAccountType.ARVOPAPERIMYYNTI
  | AsiointiLedgerAccountType.MERKINTAOSUUKSIEN_MYYNTI
  | AsiointiLedgerAccountType.HUONEISTON_MYYNTI
  | AsiointiLedgerAccountType.KIINTEISTON_MYYNTI
  | AsiointiLedgerAccountType.MUUT_PAAOMATULOT
  | AsiointiLedgerAccountType.KIRJANPITOARVON_KOROTUS
  | AsiointiLedgerAccountType.VAROJA_HALTUUN
>

export const AsiointiLedgerAccountIncomeType = {
  [AsiointiLedgerAccountType.TYOELAKE]: AsiointiLedgerAccountType.TYOELAKE,
  [AsiointiLedgerAccountType.KANSANELAKE]:
    AsiointiLedgerAccountType.KANSANELAKE,
  [AsiointiLedgerAccountType.ULKOMAAN_ELAKE]:
    AsiointiLedgerAccountType.ULKOMAAN_ELAKE,
  [AsiointiLedgerAccountType.PERHE_ELAKE]:
    AsiointiLedgerAccountType.PERHE_ELAKE,
  [AsiointiLedgerAccountType.TAKUUELAKE]: AsiointiLedgerAccountType.TAKUUELAKE,
  [AsiointiLedgerAccountType.PALKAT]: AsiointiLedgerAccountType.PALKAT,
  [AsiointiLedgerAccountType.PALKKIOT]: AsiointiLedgerAccountType.PALKKIOT,
  [AsiointiLedgerAccountType.TYOOSUUSRAHAT]:
    AsiointiLedgerAccountType.TYOOSUUSRAHAT,
  [AsiointiLedgerAccountType.OMAISHOIDON_TUKI]:
    AsiointiLedgerAccountType.OMAISHOIDON_TUKI,
  [AsiointiLedgerAccountType.ELAKKEENSAAJAN_ASUMISTUKI]:
    AsiointiLedgerAccountType.ELAKKEENSAAJAN_ASUMISTUKI,
  [AsiointiLedgerAccountType.YLEINEN_ASUMISTUKI]:
    AsiointiLedgerAccountType.YLEINEN_ASUMISTUKI,
  [AsiointiLedgerAccountType.VAMMAISTUKI]:
    AsiointiLedgerAccountType.VAMMAISTUKI,
  [AsiointiLedgerAccountType.KELAN_KUNTOUTUSRAHA]:
    AsiointiLedgerAccountType.KELAN_KUNTOUTUSRAHA,
  [AsiointiLedgerAccountType.KUNTOUTUSTUKI]:
    AsiointiLedgerAccountType.KUNTOUTUSTUKI,
  [AsiointiLedgerAccountType.SAIRAUSPAIVARAHA]:
    AsiointiLedgerAccountType.SAIRAUSPAIVARAHA,
  [AsiointiLedgerAccountType.TOIMEENTULOTUKI]:
    AsiointiLedgerAccountType.TOIMEENTULOTUKI,
  [AsiointiLedgerAccountType.KOTIHOIDON_TUKI]:
    AsiointiLedgerAccountType.KOTIHOIDON_TUKI,
  [AsiointiLedgerAccountType.ELATUSAPU]: AsiointiLedgerAccountType.ELATUSAPU,
  [AsiointiLedgerAccountType.ELATUSTUKI]: AsiointiLedgerAccountType.ELATUSTUKI,
  [AsiointiLedgerAccountType.VANHEMPAINRAHA]:
    AsiointiLedgerAccountType.VANHEMPAINRAHA,
  [AsiointiLedgerAccountType.LAPSILISA]: AsiointiLedgerAccountType.LAPSILISA,
  [AsiointiLedgerAccountType.OPINTOTUKI]: AsiointiLedgerAccountType.OPINTOTUKI,
  [AsiointiLedgerAccountType.ULKOMAAN_ETUUS]:
    AsiointiLedgerAccountType.ULKOMAAN_ETUUS,
  [AsiointiLedgerAccountType.MUU_ETUUS]: AsiointiLedgerAccountType.MUU_ETUUS,
  [AsiointiLedgerAccountType.TYOMARKKINATUKI]:
    AsiointiLedgerAccountType.TYOMARKKINATUKI,
  [AsiointiLedgerAccountType.PERUSPAIVARAHA]:
    AsiointiLedgerAccountType.PERUSPAIVARAHA,
  [AsiointiLedgerAccountType.ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA]:
    AsiointiLedgerAccountType.ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA,
  [AsiointiLedgerAccountType.KOTOUTUMISTUKI_MAAHANMUUTTAJALLE]:
    AsiointiLedgerAccountType.KOTOUTUMISTUKI_MAAHANMUUTTAJALLE,
  [AsiointiLedgerAccountType.MUU_TYOTTOMYYSTURVAETUUS]:
    AsiointiLedgerAccountType.MUU_TYOTTOMYYSTURVAETUUS,
  [AsiointiLedgerAccountType.KELAN_LAAKEKORVAUKSET]:
    AsiointiLedgerAccountType.KELAN_LAAKEKORVAUKSET,
  [AsiointiLedgerAccountType.LAAKEKORVAUKSET_MUU]:
    AsiointiLedgerAccountType.LAAKEKORVAUKSET_MUU,
  [AsiointiLedgerAccountType.SAIRASVAKUUTUSKORVAUKSET]:
    AsiointiLedgerAccountType.SAIRASVAKUUTUSKORVAUKSET,
  [AsiointiLedgerAccountType.HENKIVAKUUTUSKORVAUKSET]:
    AsiointiLedgerAccountType.HENKIVAKUUTUSKORVAUKSET,
  [AsiointiLedgerAccountType.VAHINKOVAKUUTUSKORVAUKSET]:
    AsiointiLedgerAccountType.VAHINKOVAKUUTUSKORVAUKSET,
  [AsiointiLedgerAccountType.VAHINGONKORVAUKSET]:
    AsiointiLedgerAccountType.VAHINGONKORVAUKSET,
  [AsiointiLedgerAccountType.MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET]:
    AsiointiLedgerAccountType.MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET,
  [AsiointiLedgerAccountType.MAKSUN_PALAUTUKSET]:
    AsiointiLedgerAccountType.MAKSUN_PALAUTUKSET,
  [AsiointiLedgerAccountType.VERONPALAUTUKSET]:
    AsiointiLedgerAccountType.VERONPALAUTUKSET,
  [AsiointiLedgerAccountType.KATEISVAROJA_HALTUUN]:
    AsiointiLedgerAccountType.KATEISVAROJA_HALTUUN,
  [AsiointiLedgerAccountType.LAHJAT_PERINNOT_TASINKO]:
    AsiointiLedgerAccountType.LAHJAT_PERINNOT_TASINKO,
  [AsiointiLedgerAccountType.MUUT_TULOT]: AsiointiLedgerAccountType.MUUT_TULOT,
  [AsiointiLedgerAccountType.VIRHETULO_KORJATAAN_MYOHEMMIN]:
    AsiointiLedgerAccountType.VIRHETULO_KORJATAAN_MYOHEMMIN,
  [AsiointiLedgerAccountType.VIRHEMAKSUN_KORJAUS]:
    AsiointiLedgerAccountType.VIRHEMAKSUN_KORJAUS,
  [AsiointiLedgerAccountType.VUOKRATULOT]:
    AsiointiLedgerAccountType.VUOKRATULOT,
  [AsiointiLedgerAccountType.OSINKOTULOT]:
    AsiointiLedgerAccountType.OSINKOTULOT,
  [AsiointiLedgerAccountType.KORKOTULOT]: AsiointiLedgerAccountType.KORKOTULOT,
  [AsiointiLedgerAccountType.METSANMYYNTI]:
    AsiointiLedgerAccountType.METSANMYYNTI,
  [AsiointiLedgerAccountType.IRTAIMEN_MYYNTI]:
    AsiointiLedgerAccountType.IRTAIMEN_MYYNTI,
  [AsiointiLedgerAccountType.ARVOPAPERIMYYNTI]:
    AsiointiLedgerAccountType.ARVOPAPERIMYYNTI,
  [AsiointiLedgerAccountType.MERKINTAOSUUKSIEN_MYYNTI]:
    AsiointiLedgerAccountType.MERKINTAOSUUKSIEN_MYYNTI,
  [AsiointiLedgerAccountType.HUONEISTON_MYYNTI]:
    AsiointiLedgerAccountType.HUONEISTON_MYYNTI,
  [AsiointiLedgerAccountType.KIINTEISTON_MYYNTI]:
    AsiointiLedgerAccountType.KIINTEISTON_MYYNTI,
  [AsiointiLedgerAccountType.MUUT_PAAOMATULOT]:
    AsiointiLedgerAccountType.MUUT_PAAOMATULOT,
  [AsiointiLedgerAccountType.KIRJANPITOARVON_KOROTUS]:
    AsiointiLedgerAccountType.KIRJANPITOARVON_KOROTUS,
  [AsiointiLedgerAccountType.VAROJA_HALTUUN]:
    AsiointiLedgerAccountType.VAROJA_HALTUUN,
} as const
