import moment from 'moment'

export type DigitMode = 'none' | 'two' | 'five'
export type UnitType = 'eur' | 'percentage' | 'amount'

export const parseStringAsNumberWithDigits = (
  value: string | undefined,
  digitMode: DigitMode
): number => {
  if (value === undefined) {
    return NaN
  }
  const stripped = value.replace(/\s/g, '')
  const normalized = stripped.replace(',', '.')
  if (digitMode === 'two' && /^[0-9]+([\\.][0-9]{1,2})?$/.test(normalized)) {
    return Number(normalized)
  } else if (
    digitMode === 'five' &&
    /^[0-9]+([\\.][0-9]{1,5})?$/.test(normalized)
  ) {
    return Number(normalized)
  } else if (digitMode === 'none' && /^[0-9]*$/.test(normalized)) {
    return Number(normalized)
  } else {
    return NaN
  }
}

export const formatNumberAsEuroAndCents = (
  value: number,
  digitMode: DigitMode
): string => {
  return value.toLocaleString(['fi-FI', 'sv-FI'], {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: digitMode === 'five' ? 5 : 2,
  })
}

export const formatNumberWithComma = (
  value: number,
  digitMode?: DigitMode
): string => {
  return formatNumberAsEuroAndCents(value, digitMode || 'two').replace('.', ',')
}

export const formatDateAsLocal = (value: string): string => {
  const d = moment(value, 'YYYY-MM-DD', true)
  if (d.isValid()) {
    return d.format('D.M.YYYY')
  } else {
    return value
  }
}

export const parseLocalDate = (value: string): string => {
  const d = moment(value, 'D.M.YYYY', true)
  if (d.isValid()) {
    return d.format('YYYY-MM-DD')
  } else {
    return value
  }
}

export const formatTilinumeroInput = (input: string): string => {
  if (input.toUpperCase().startsWith('FI')) {
    input = input.replace(/\s+/g, '')
    return input.replace(/(.{4})/g, '$1 ').trim()
  }
  return input
}
