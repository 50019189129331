import { observer } from 'mobx-react'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkHoitosuunnitelmaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { action } from 'mobx'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

export const HoitosuunnitelmaForm = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const {
    lomake: { tili, lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkHoitosuunnitelmaTestId('container')}>
      <Heading variant="h4" mb="s">
        {t('hoitoJaPalvelusuunnitelmaHeading')}
      </Heading>
      <Paragraph>{t('hoitoJaPalvelusuunnitelmaOhje')}</Paragraph>
      <Block mt={verticalMarginToken}>
        <FormRadioButtonGroup
          data-test-id={mkHoitosuunnitelmaTestId(
            'onko-tehty-hoitosuunnitelma-radio'
          )}
          required
          value={lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma?.toString()}
          labelText={t('onkoHenkilolleTehtyAsumisenHoitoJaPalvelusuunnitelma')}
          updateValue={action(
            (value) =>
              (lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma =
                value === 'true')
          )}
          radioButtons={[
            {
              value: 'false',
              labelText: t('ei'),
            },
            { value: 'true', labelText: t('kylla') },
          ]}
        />
      </Block>

      {lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma && (
        <Block mt={verticalMarginToken}>
          <FormAttachmentFileBox
            observableAttachments={tili.asuminen.hoitoPalveluSuunnitelma}
            asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
            data-test-id={mkHoitosuunnitelmaTestId('attachment-file-box')}
            title={t('asiakirjat')}
            text={t('ohjeHoitosuunnitelmaAsiakirjat')}
          />
        </Block>
      )}
      {lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma === false &&
        tili.asuminen.hoitoPalveluSuunnitelma.length > 0 && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})
