import styled from 'styled-components'
import { suomifiDesignTokens, TextInput } from 'suomifi-ui-components'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import React from 'react'
import { observer } from 'mobx-react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { lowerFirst } from 'lodash'

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding-bottom: ${suomifiDesignTokens.spacing.m};
  max-width: ${(props) => (props.inputMode === 'decimal' ? '290px' : '450px')};
  @media screen and ${device.tablet} {
    padding-bottom: ${suomifiDesignTokens.spacing.xl};
  }

  .fi-text-input_character-counter {
    display: none;
  }
`

export type FormTextInputProps = {
  'data-test-id': string
  labelText?: string
  optionalText?: string
  hintText?: string
  value?: string
  defaultValue?: string
  required?: boolean
  validate?: Validate<string | null | undefined, FieldValues>
  updateValue: (value: string) => void
  requiredLabel?: string
  characterLimit?: number
  autoComplete?: string
  type?: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url'
}

export const FormTextInput: React.FC<FormTextInputProps> = observer(
  ({
    'data-test-id': dataTestId,
    labelText,
    hintText,
    value,
    defaultValue,
    required = false,
    validate,
    updateValue,
    requiredLabel,
    characterLimit = 200,
    autoComplete = 'off',
    type,
    ...rest
  }) => {
    if (!dataTestId) {
      throw new Error('Missing data test id')
    }
    const [t] = useTranslation()
    const {
      register,
      formState: { errors },
    } = useFormContext()

    const { onChange, ref, name } = register(dataTestId, {
      required: {
        value: required,
        message: requiredLabel ?? `${t('kirjoita')} ${lowerFirst(labelText)}`,
      },
      validate,
      maxLength: {
        value: characterLimit,
        message: t('virheTekstinPituusYlittaaMerkkimaaran', {
          maxLength: characterLimit,
        }),
      },
      onChange: (event) => updateValue(event.target.value),
    })

    return (
      <StyledTextInput
        data-test-id={dataTestId}
        optionalText={required ? undefined : t('valinnainen')}
        status={errors[dataTestId] ? 'error' : 'default'}
        statusTextAriaLiveMode="off"
        ariaCharactersRemainingText={(amount) =>
          `${characterLimit - amount} / ${characterLimit}`
        }
        ariaCharactersExceededText={(_amount) =>
          t('virheTekstinPituusYlittaaMerkkimaaran', {
            maxLength: characterLimit,
          })
        }
        characterLimit={characterLimit}
        statusText={(errors[dataTestId]?.message || '') as string}
        data-test-error-message={(errors[dataTestId]?.message || '') as string}
        onChange={(value) => onChange(createOnChangeEvent(value, dataTestId))}
        ref={ref}
        name={name}
        {...{
          defaultValue,
          value,
          hintText,
          labelText,
          autoComplete,
          type,
          ...rest,
        }}
      />
    )
  }
)

export default FormTextInput
