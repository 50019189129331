import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Lomake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const getHallintaoikeudet = (
  { tili: { sopimustiedot }, lomakkeelleValitutTiedot }: Lomake,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const content: YhteenvetoGroupProps[] = [
    {
      heading: t(
        isOmaisuusluettelo()
          ? 'onkoHallintaoikeusiaToisenOmaisuuteen'
          : 'onkoOllutSovittujaHallintaoikeuksia'
      ),
      rows: [
        lomakkeelleValitutTiedot.hallintaoikeudet !== undefined
          ? lomakkeelleValitutTiedot.hallintaoikeudet
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (lomakkeelleValitutTiedot.hallintaoikeudet) {
    content.push({
      heading: t('mitenHallintaoikeuksistaOnSovittu'),
      rows: [sopimustiedot.hallintaoikeudet.mitenSovittu],
      attachments: sopimustiedot.hallintaoikeudet.sopimukset,
    })
  }

  return content
}

const HallintaOikeudet: React.FC = observer(() => {
  const [t] = useTranslation()
  const { lomake } = getTilintarkastusStore()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('hallintaoikeudet-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('hallintaoikeudet')}
      </Heading>
      <YhteenvetoGroups groups={getHallintaoikeudet(lomake, t)} />
    </section>
  )
})

export default HallintaOikeudet
