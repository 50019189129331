import { action, flow, observable, toJS } from 'mobx'
import {
  TilintarkastusStore,
  TilitarkastusStoreProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store.type'
import { CancellablePromise } from 'mobx/dist/api/flow'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { TILINTARKASTUS_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { saveDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'

let store: TilintarkastusStore | undefined

export const initTilintarkastusStore = ({
  vaatimus,
  visitedSteps,
  lomake,
  draft,
}: TilitarkastusStoreProps): TilintarkastusStore => {
  const excludeSteps = new Set<string>(
    vaatimus.asiaType === 'OMAISUUSLUETTELO'
      ? vaatimus.tilivelvollisuusType !==
        TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA
        ? [
            TILINTARKASTUS_ROUTES.VALTAKIRJA,
            TILINTARKASTUS_ROUTES.TULOT,
            TILINTARKASTUS_ROUTES.MENOT,
            TILINTARKASTUS_ROUTES.PALKKIO,
          ]
        : [
            TILINTARKASTUS_ROUTES.TULOT,
            TILINTARKASTUS_ROUTES.MENOT,
            TILINTARKASTUS_ROUTES.PALKKIO,
          ]
      : vaatimus.tilivelvollisuusType !==
        TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA
      ? [TILINTARKASTUS_ROUTES.VALTAKIRJA]
      : []
  )

  store = observable({
    draft,
    lomake,
    vaatimus,
    visitedSteps: new Set(visitedSteps),
    excludeSteps,
    submitStatus: vaatimus.sentDate ? 'submitted' : 'none',
    submitError: undefined,
  } as TilintarkastusStore)
  return store
}

export const getTilintarkastusStore = (): TilintarkastusStore => {
  if (store) {
    return store
  } else {
    throw new Error('TilintarkastusStore not initialized')
  }
}

export const isTilintarkastusStoreInitialized = (
  seurantaAsiavaatimusId: string
): boolean => {
  if (store && store.lomake) {
    return store.vaatimus?.seurantaAsiavaatimusId === seurantaAsiavaatimusId
  }
  return false
}

export const runTilintarkastusStoreFlow = <T>(
  fn: (store: TilintarkastusStore) => Generator<any, T, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): CancellablePromise<T> => {
  const store = getTilintarkastusStore()
  return flow<T, [TilintarkastusStore]>(fn)(store)
}

export const runTilintarkastusStoreAction = <T>(
  fn: (store: TilintarkastusStore) => T
): T => {
  const store = getTilintarkastusStore()
  return action(fn)(store)
}

export const saveTiliAsDraft = async (): Promise<void> => {
  const { submitStatus, lomake, visitedSteps, vaatimus } =
    getTilintarkastusStore()
  if (submitStatus === 'submitted') {
    return
  }
  const updatedAt = new Date().toISOString() as LongIsoDateString
  const draft = {
    sourceId: vaatimus.seurantaAsiavaatimusId.toString(),
    type: vaatimus.asiaType,
    data: toJS({ lomake, visitedSteps: Array.from(visitedSteps) }), // a non-observable copy of the original to avoid it changing
    updatedAt, // user specific date to avoid re-renders
  }

  await saveDraft(draft)
  runTilintarkastusStoreAction((store) => {
    store.draft = draft
  })
}
