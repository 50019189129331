import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import {
  GetUserDraftsResponse,
  UpsertDraftRequest,
  UpsertDraftResponse,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'

export const getDrafts = async (): Promise<GetUserDraftsResponse> => {
  const apiResponse = await executeBackendClient<GetUserDraftsResponse>(
    (client) => client.get('/asiointi/api/v1/draft')
  )

  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed getting draft data: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}

export const saveTiliDraft = async (
  lomakeDraft: UpsertDraftRequest
): Promise<UpsertDraftResponse> => {
  const apiResponse = await executeBackendClient<UpsertDraftResponse>(
    (client) => client.post('/asiointi/api/v1/draft/upsert', lomakeDraft)
  )
  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed saving draft data: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}
