import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontKayttovara } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const KayttovaratForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const tilirivit = getTilirivit()

  const mkNewElement = (): FrontKayttovara =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountType.KAYTTOVARAT,
      yhteensa: undefined,
      tositteet: [],
    })

  return (
    <section data-test-id={mkTulotMenotTestId('kayttovarat-form')}>
      <Heading variant="h3" mb="xs">
        {t('kayttovaratHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaKayttovarat')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={KayttovaraListItem}
        state={tilirivit.menot.kayttovarat}
        dataTestIdPrefix={mkTulotMenotTestId('kayttovarat-form')}
        ModalContentComponent={KayttovaraModal}
        newEntry={mkNewElement}
        translationPrefix="kayttovarat"
        tiliLomakeKentta={TiliLomakeKentta.kayttovarat}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const KayttovaraModal: FC<FormModalProps<FrontKayttovara>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    return (
      <div>
        <FormNumberInput
          data-test-id={createDataTestId('modal-menotYhteensa')}
          labelText={t('kayttovaratYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />

        <FormAttachmentFileBox
          observableAttachments={entry.tositteet}
          asiakirjaTypeId={TiliAsiakirjaType.TOSITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('kayttovaratAsiakirjaInfo')}
          title={t('tositteet')}
        />
      </div>
    )
  }
)

const KayttovaraListItem: FC<FormElementProps<FrontKayttovara>> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    const yhteensaEur =
      entry.yhteensa !== undefined
        ? `${formatNumberWithComma(entry.yhteensa)} €`
        : undefined

    return (
      <FormListInputExpander
        heading={t(`expenseType_${entry.tyyppi}`)}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        pakollinenTietoPuuttuu={entry.yhteensa === undefined}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t('menotTilikaudenAikanaYhteensaEuroina')}
          content={yhteensaEur}
        />
        {entry.tositteet.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('tositteet')}</Heading>
            <AttachmentList attachments={entry.tositteet} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default KayttovaratForm
