import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'

export const getTili = (): TiliData => {
  return getTilintarkastusStore().lomake.tili
}

export const isOmaisuusluettelo = (): boolean => {
  return getTilintarkastusStore().vaatimus.asiaType === 'OMAISUUSLUETTELO'
}

export const getTilirivit = (): TiliData['tilirivit'] =>
  getTilintarkastusStore().lomake.tili.tilirivit
