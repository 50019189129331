import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import {
  AvaaTiliLomakeRequest,
  AvaaTiliLomakeResponse,
  LahetaTiliLomakeApiRequest,
  LahetaTiliLomakeApiResponse,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tilintarkastus-api/tilintarkastus-api.type'

export const TilintarkastusApiClient = {
  lahetaTiliLomake: (
    data: LahetaTiliLomakeApiRequest
  ): Promise<ApiResponse<LahetaTiliLomakeApiResponse>> =>
    executeBackendClient((client) => {
      return client.post(
        '/tilintarkastus-asiointi/api/v1/laheta-tili-lomake',
        data
      )
    }),

  avaaTiliLomake: (
    data: AvaaTiliLomakeRequest
  ): Promise<ApiResponse<AvaaTiliLomakeResponse>> =>
    executeBackendClient((client) => {
      return client.post(
        '/tilintarkastus-asiointi/api/v1/avaa-tili-lomake',
        data
      )
    }),
}
