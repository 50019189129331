export enum TiliLomakeKentta {
  saannollisetTulot = 'saannollisetTulot',
  elinkustannuksiinKaytettavatElaketulot = 'elinkustannuksiinKaytettavatElaketulot',
  kayttovarakaytanto = 'kayttovarakaytanto',
  muutOmassaKaytossaOlevatVarat = 'muutOmassaKaytossaOlevatVarat',
  pankkitilit = 'pankkitilit',
  omaisuusOmassaKaytossa = 'omaisuusOmassaKaytossa',
  muutMerkittavatToimet = 'muutMerkittavatToimet',
  testamentti = 'testamentti',
  sopimukset = 'sopimukset',
  hallintaoikeudet = 'hallintaoikeudet',
  vakuutukset = 'vakuutukset',
  asuinpaikka = 'asuinpaikka',
  huoltajalla = 'huoltajalla',
  toimintakyky = 'toimintakyky',
  talletukset = 'talletukset',
  arvopaperit = 'arvopaperit',
  osuudetHenkiloYhtiossaJaYrityksissa = 'osuudetHenkiloYhtiossaJaYrityksissa',
  huoneistot = 'huoneistot',
  kiinteistot = 'kiinteistot',
  irtaimistot = 'irtaimistot',
  saatavat = 'saatavat',
  muutVarat = 'muutVarat',
  osuudetKuolinpesissa = 'osuudetKuolinpesissa',
  pankkilainat = 'pankkilainat',
  takaukset = 'takaukset',
  muutVelat = 'muutVelat',
  kayttovarat = 'kayttovarat',
  elinkustannukset = 'elinkustannukset',
  palvelumaksut = 'palvelumaksut',
  vuokratVastikkeet = 'vuokratVastikkeet',
  elatusavut = 'elatusavut',
  pankinPerimatMaksut = 'pankinPerimatMaksut',
  verot = 'verot',
  tyonantajakulut = 'tyonantajakulut',
  velanhoitomenot = 'velanhoitomenot',
  edunvalvonnankulut = 'edunvalvonnankulut',
  muutMenot = 'muutMenot',
  elakkeet = 'elakkeet',
  palkkatulot = 'palkkatulot',
  etuudet = 'etuudet',
  tyottomyysturva = 'tyottomyysturva',
  vuokratulot = 'vuokratulot',
  osinkotulot = 'osinkotulot',
  korkotulot = 'korkotulot',
  myyntitulot = 'myyntitulot',
  muutTulot = 'muutTulot',
  hoitoPalveluSuunnitelma = 'hoitoPalveluSuunnitelma',
  tiedoksiannettava = 'tiedoksiannettava',
}

export type TiliLomakeKenttaId = keyof typeof TiliLomakeKentta

export const LomakeToKenttaMapping = Object.freeze({
  tulotiedot: {
    saannollisetTulot: TiliLomakeKentta.saannollisetTulot,
    elaketulotElinkustannuksiin: {
      elinkustannuksiinKaytettavatElaketulot:
        TiliLomakeKentta.elinkustannuksiinKaytettavatElaketulot,
    },
  },
  paamiehenOmaisuus: {
    kayttovarakaytanto: TiliLomakeKentta.kayttovarakaytanto,
    muutVarat: TiliLomakeKentta.muutOmassaKaytossaOlevatVarat,
    pankkitilit: TiliLomakeKentta.pankkitilit,
    omaisuusOmassaKaytossa: TiliLomakeKentta.omaisuusOmassaKaytossa,
  },
  muutMerkittavatToimet: TiliLomakeKentta.muutMerkittavatToimet,
  testamentti: {
    laadittu: TiliLomakeKentta.testamentti,
    rajoittaviaEhtoja: TiliLomakeKentta.testamentti,
  },
  sopimustiedot: {
    sopimukset: TiliLomakeKentta.sopimukset,
    hallintaoikeudet: {
      mitenSovittu: TiliLomakeKentta.hallintaoikeudet,
    },
  },
  vakuutukset: TiliLomakeKentta.vakuutukset,
  asuminen: {
    asuinpaikat: TiliLomakeKentta.asuinpaikka,
    huoltajalla: TiliLomakeKentta.huoltajalla,
  },
  toimintakyky: TiliLomakeKentta.toimintakyky,
  tilirivit: {
    varat: {
      talletukset: TiliLomakeKentta.talletukset,
      arvopaperit: TiliLomakeKentta.arvopaperit,
      osuudetHenkiloYhtiossaJaYrityksissa:
        TiliLomakeKentta.osuudetHenkiloYhtiossaJaYrityksissa,
      huoneistot: TiliLomakeKentta.huoneistot,
      kiinteistot: TiliLomakeKentta.kiinteistot,
      irtaimistot: TiliLomakeKentta.irtaimistot,
      saatavat: TiliLomakeKentta.saatavat,
      muutVarat: TiliLomakeKentta.muutVarat,
      osuudetKuolinpesissa: TiliLomakeKentta.osuudetKuolinpesissa,
    },
    velat: {
      pankkilainat: TiliLomakeKentta.pankkilainat,
      takaukset: TiliLomakeKentta.takaukset,
      muutVelat: TiliLomakeKentta.muutVelat,
    },
    menot: {
      kayttovarat: TiliLomakeKentta.kayttovarat,
      elinkustannukset: TiliLomakeKentta.elinkustannukset,
      palvelumaksut: TiliLomakeKentta.palvelumaksut,
      vuokratVastikkeet: TiliLomakeKentta.vuokratVastikkeet,
      elatusavut: TiliLomakeKentta.elatusavut,
      pankinPerimatMaksut: TiliLomakeKentta.pankinPerimatMaksut,
      verot: TiliLomakeKentta.verot,
      tyonantajakulut: TiliLomakeKentta.tyonantajakulut,
      velanhoitomenot: TiliLomakeKentta.velanhoitomenot,
      edunvalvonnankulut: TiliLomakeKentta.edunvalvonnankulut,
      muutMenot: TiliLomakeKentta.muutMenot,
    },
    tulot: {
      elakkeet: TiliLomakeKentta.elakkeet,
      palkat: TiliLomakeKentta.palkkatulot,
      etuudet: TiliLomakeKentta.etuudet,
      tyottomyysturva: TiliLomakeKentta.tyottomyysturva,
      vuokratulot: TiliLomakeKentta.vuokratulot,
      osinkotulot: TiliLomakeKentta.osinkotulot,
      korkotulot: TiliLomakeKentta.korkotulot,
      myyntitulot: TiliLomakeKentta.myyntitulot,
      muutTulot: TiliLomakeKentta.muutTulot,
    },
  },
})
