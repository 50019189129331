import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const getLisatiedotContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const { lisatiedot } = account
  if (lisatiedot) {
    return [
      {
        heading: t('lisatietojaTextInputLabel'),
        rows: [lisatiedot],
      },
    ]
  } else {
    return []
  }
}

const Lisatiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('lisatiedot')}
      </Heading>
      <YhteenvetoGroups groups={getLisatiedotContent(tili, t)} />
    </>
  )
})

export default Lisatiedot
