import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  getEdunvalvonnanKulutContent,
  getElatusavutContent,
  getElinkustannuksetContent,
  getKayttovaratContent,
  getMuutMenotContent,
  getPalvelumaksutContent,
  getPankinPerimatMaksutContent,
  getTyonantajakulutContent,
  getVelanhoitomenotContent,
  getVerotContent,
  getVuokratVastikkeetContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/menot.util'

const Menot: React.FC = observer(() => {
  const [t] = useTranslation()
  const lomake = getTilintarkastusStore().lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: { lomakkeelleValitutTiedot: valittuLomakkeelle },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkYhteenvetoTestId('menot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('menotHeading')}
      </Heading>

      {!isPrincipalUnderaged && (
        <>
          <Heading variant="h4" mb={verticalMarginToken}>
            {t('kayttovaratHeading')}
          </Heading>
          <YhteenvetoGroups
            groups={
              valittuLomakkeelle['kayttovarat']
                ? getKayttovaratContent(lomake, t)
                : []
            }
          />
        </>
      )}

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('elinkustannuksetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['elinkustannukset']
            ? getElinkustannuksetContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('palvelumaksutHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['palvelumaksut']
            ? getPalvelumaksutContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('vuokratVastikkeetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['vuokratVastikkeet']
            ? getVuokratVastikkeetContent(lomake, t)
            : []
        }
      />

      {!isPrincipalUnderaged && (
        <>
          <Heading variant="h4" mb={verticalMarginToken}>
            {t('elatusavutHeading')}
          </Heading>
          <YhteenvetoGroups
            groups={
              valittuLomakkeelle['elatusavut']
                ? getElatusavutContent(lomake, t)
                : []
            }
          />
        </>
      )}

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('pankkimaksutHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['pankinPerimatMaksut']
            ? getPankinPerimatMaksutContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('verotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={valittuLomakkeelle['verot'] ? getVerotContent(lomake, t) : []}
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('tyonantajakulutHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['tyonantajakulut']
            ? getTyonantajakulutContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('velanhoitomenotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['velanhoitomenot']
            ? getVelanhoitomenotContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('edunvalvonnankulutHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['edunvalvonnankulut']
            ? getEdunvalvonnanKulutContent(lomake, t)
            : []
        }
      />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('muutMenotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['muutMenot'] ? getMuutMenotContent(lomake, t) : []
        }
      />
    </section>
  )
})

export default Menot
