import { StaticChip, suomifiDesignTokens } from 'suomifi-ui-components'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { IsoDatetimeToUiDatetime } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import { AsiointiDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'

const TiliStatusChip: React.FC<{
  vaatimus: Pick<
    AsiointiSeurantaAsiavaatimus,
    'seurantaAsiavaatimusId' | 'sentDate' | 'lomakeBlockedReason'
  >
  draft?: AsiointiDraft
}> = observer(({ vaatimus, draft }) => {
  const [t] = useTranslation()

  return (
    <section data-test-id={mkTestId('tili-status-chip')}>
      {vaatimus.sentDate ? (
        <ChipForStatusSent>
          {t('tiliStatus_LAHETETTY')}&nbsp;{'✔'}
        </ChipForStatusSent>
      ) : vaatimus.lomakeBlockedReason === 'ODOTTAA_EDELLISTA' ? (
        <ChipForStatusOdottaa>{t('odottaa')}</ChipForStatusOdottaa>
      ) : draft ? (
        <ChipForStatusSaved>
          {t('tiliStatus_TALLENNETTU')}&nbsp;
          {IsoDatetimeToUiDatetime(draft.updatedAt)}
        </ChipForStatusSaved>
      ) : (
        <ChipForStatusNotStarted>
          {t('tiliStatus_ALOITTAMATTA')}
        </ChipForStatusNotStarted>
      )}
    </section>
  )
})

export default TiliStatusChip

const ChipForStatusNotStarted = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background-color: ${suomifiDesignTokens.colors.warningLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSent = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.successSecondary};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSaved = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.highlightLight3};
    color: ${suomifiDesignTokens.colors.depthDark1};
    font-size: 14px;
  }
`

const ChipForStatusOdottaa = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.depthLight2};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`
