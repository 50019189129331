import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTilirivit,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontKuolinpesaOsakkuus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseLocalDate,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const KuolinpesaOsakkuudetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const omaisuus = getTilirivit()
  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  const mkNewEntry = (): FrontKuolinpesaOsakkuus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.OSUUDET_KUOLINPESISSA,
      vainajanEtunimi: '',
      vainajanSukunimi: '',
      vainajanKuolinpaiva: undefined,
      osakkuudenOsuus: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}>
      <Heading variant="h3" mb="xs">
        {t('kuolinpesaOsakkuudetHeading')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t('ohjeIlmoitaKuolinpesaOsakkuudet')}
      </Paragraph>
      <FormListInput
        ExpanderComponent={KuolinpesaOsakkuudetListItem}
        ModalContentComponent={KuolinpesaOsakkuudetModal}
        state={omaisuus.varat.osuudetKuolinpesissa}
        dataTestIdPrefix={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}
        newEntry={mkNewEntry}
        translationPrefix="kuolinpesaOsakkuudet"
        tiliLomakeKentta={TiliLomakeKentta.osuudetKuolinpesissa}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const KuolinpesaOsakkuudetModal: FC<FormModalProps<FrontKuolinpesaOsakkuus>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const { vaatimus: evtv } = getTilintarkastusStore()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)

    const translations =
      translationsByType[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']
    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanEtunimi')}
          labelText={t('vainajanEtunimi')}
          value={entry.vainajanEtunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanEtunimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanSukunimi')}
          labelText={t('vainajanSukunimi')}
          value={entry.vainajanSukunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanSukunimi: value })
          }
        />

        <FormDateInput
          labelText={t('vainajanKuolinpaiva')}
          data-test-id={createDataTestId('modal-vainajanKuolinpaiva')}
          value={
            entry.vainajanKuolinpaiva
              ? formatDateAsLocal(entry.vainajanKuolinpaiva)
              : entry.vainajanKuolinpaiva
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              vainajanKuolinpaiva: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-osakkuudenOsuus')}
          labelText={t('henkilonOsuusKuolinpesa')}
          hintText={t('ilmoitaProsenttiTaiJakoOsuus')}
          required
          value={entry.osakkuudenOsuus}
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({
              ...entry,
              osakkuudenOsuus: value,
            })
          }
        />

        {!isOmaisuusluettelo() && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          hintText={
            isOmaisuusluettelo()
              ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
              : undefined
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={
            isOmaisuusluettelo()
              ? TiliAsiakirjaType.PERUKIRJA
              : TiliAsiakirjaType.MUU_LIITE
          }
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translations.asiakirjatInfo)}
          title={t('asiakirjat')}
        />
      </div>
    )
  })

const KuolinpesaOsakkuudetListItem: FC<
  FormElementProps<FrontKuolinpesaOsakkuus>
> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'
    const translations =
      translationsByType[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    const henkilonProsenttiosuus = parseStringAsNumberWithDigits(
      entry.osakkuudenOsuus,
      'two'
    )
    const osuusKuolinpesasta = henkilonProsenttiosuus
      ? `${formatNumberWithComma(henkilonProsenttiosuus)} %`
      : entry.osakkuudenOsuus

    const arvoLopussaEur =
      entry.arvoTilikaudenLopussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
        : undefined

    const arvoAlussaEur =
      entry.arvoTilikaudenAlussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
        : ''

    const kuolinpaiva = entry.vainajanKuolinpaiva
      ? formatDateAsLocal(entry.vainajanKuolinpaiva)
      : t('vainajanKuolinpaivaEiTiedossa')

    return (
      <FormListInputExpander
        heading={`${entry.vainajanSukunimi}, ${entry.vainajanEtunimi}`}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        pakollinenTietoPuuttuu={entry.arvoTilikaudenLopussaEur === undefined}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        <Heading variant="h5">{t('vainajanKuolinpaiva')}</Heading>
        <Text>{kuolinpaiva}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('osakkuudenOsuus')}</Heading>
        <Text>{osuusKuolinpesasta}</Text>
        <Block mt={verticalMarginToken} />

        {!isOmaisuusluettelo() && (
          <>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{t(arvoAlussaEur)}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t(translations.arvoLopussa)}
          content={arvoLopussaEur}
        />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

const translationsByType = {
  OMAISUUSLUETTELO: {
    asiakirjatInfo: 'asiakirjaKuolinpesa',
    arvoLopussa: 'arvoEuroina',
  },
  TILI: {
    asiakirjatInfo: 'kuolinpesaOsakkuudetAsiakirjaInfo',
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
  },
}

export default KuolinpesaOsakkuudetForm
