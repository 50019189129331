import {
  AsiointiHenkilo,
  AsiointiSeurantaAsiavaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import { flow, observable, runInAction } from 'mobx'
import * as evtvApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/evtv-api-client'

export interface EvtvStore {
  vaatimukset: AsiointiSeurantaAsiavaatimus[]
  henkilot: AsiointiHenkilo[]
  valittuHenkilo?: AsiointiHenkilo
  isLoading: boolean
  isInitialized: boolean
  isError: boolean
}

let evtvStore: EvtvStore = observable({
  vaatimukset: [],
  henkilot: [],
  valittuHenkilo: undefined,
  isLoading: false,
  isInitialized: false,
  isError: false,
})

export function createEvtvStore(data: EvtvStore): EvtvStore {
  evtvStore = observable(data)
  return evtvStore
}

export const useVaatimukset = (): AsiointiSeurantaAsiavaatimus[] => {
  return evtvStore.vaatimukset
}

export const useHenkilot = (): AsiointiHenkilo[] => {
  return evtvStore.henkilot
}

export const useEvtvStore = (): EvtvStore => {
  return evtvStore
}

export const getValittuHenkilo = (): AsiointiHenkilo | undefined => {
  return evtvStore.valittuHenkilo
}

export const useValittuHenkilo = (): AsiointiHenkilo => {
  const paamies = evtvStore.valittuHenkilo
  if (!paamies) {
    throw new Error('evtvStore.valittuHenkilo not initialized')
  }
  return paamies
}

export const clearValittuHenkilo = (): void => {
  runInAction(() => {
    evtvStore.valittuHenkilo = undefined
  })
}

export const getEvtvVaatimus = (
  seurantaAsiaVaatimusId: string
): AsiointiSeurantaAsiavaatimus => {
  const vaatimus = evtvStore.vaatimukset?.find(
    (vaatimus) => vaatimus.seurantaAsiavaatimusId === seurantaAsiaVaatimusId
  )
  if (!vaatimus) {
    throw new Error(`Missing evtv vaatimus ${seurantaAsiaVaatimusId}`)
  }
  return vaatimus
}

export const initializeEvtvStore = async (): Promise<void> => {
  if (!evtvStore.isInitialized) {
    await fetchEvtvDataIntoStore()
  }
}

export const fetchEvtvDataIntoStore = flow(function* () {
  evtvStore.isLoading = true
  evtvStore.isError = false
  const { fetchSuccess, vaatimukset, henkilot } = yield evtvApi.getEvtvData()
  evtvStore.vaatimukset.splice(0, evtvStore.vaatimukset.length)
  evtvStore.vaatimukset.push(...vaatimukset)
  evtvStore.henkilot.splice(0, evtvStore.henkilot.length)
  evtvStore.henkilot.push(...henkilot)
  evtvStore.isError = !fetchSuccess
  evtvStore.isLoading = false
  evtvStore.isInitialized = true
  return evtvStore.isInitialized
})

export const valitseHenkiloByPersonId = (
  personId: string
): AsiointiHenkilo | undefined => {
  if (evtvStore.henkilot?.length) {
    const henkilo = evtvStore.henkilot.find(
      (henkilo) => henkilo.personId === personId
    )
    if (henkilo) {
      runInAction(() => {
        evtvStore.valittuHenkilo = henkilo
      })
      return evtvStore.valittuHenkilo
    }
  }
}
