import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { AsiointiElaketyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const getSaannollisetTulot = (
  isPrincipalUnderaged: boolean,
  { tulotiedot }: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  return tulotiedot.saannollisetTulot.map((st) => ({
    heading: `${
      st.tyyppi ? t('incomeType_' + st.tyyppi) + ': ' : ''
    } ${formatNumberWithComma(st.nettosumma || 0)} €`,
    rows: [
      [st.maksaja, `${getPaivamaaraString(t, st.beginDate, st.endDate)}`].join(
        ','
      ),
      isPrincipalUnderaged && st.mihinPankkitililleMaksetaan !== undefined
        ? `${t('elakkeenMaksaminen')}: ${t(
            `AlaikaisenTilinTyyppi_${st.mihinPankkitililleMaksetaan}`
          )}`
        : undefined,
    ],
    attachments: st.asiakirjat,
  }))
}

const getAlaikaisenElinkustannuksiinKaytettavatElakkeet = (
  { tulotiedot }: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const elaketulot = tulotiedot.saannollisetTulot
    .filter((tulo) =>
      tulo.tyyppi ? AsiointiElaketyyppi.includes(tulo.tyyppi) : []
    )
    .reduce((acc, tulo) => (acc += tulo.nettosumma || 0), 0)
  return [
    {
      heading: `${t('elakkeetYhteensa')}: ${t('elaketulotSum', {
        elaketulot: formatNumberWithComma(elaketulot),
      })}`,
      rows: [
        tulotiedot.elaketulotElinkustannuksiin.enKaytaElakettaElinkustannuksiin
          ? t('enKaytaElinkustannuksiin')
          : `${t('kustannuksiinKaytettavaMaara')}: ${
              tulotiedot.elaketulotElinkustannuksiin
                .elinkustannuksiinKaytettavatElaketulot !== undefined
                ? formatNumberWithComma(
                    tulotiedot.elaketulotElinkustannuksiin
                      .elinkustannuksiinKaytettavatElaketulot
                  )
                : ''
            }`,
      ],
    },
  ]
}

const SaannollisetTulot: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: { tili, lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('saannolliset-tulot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {isOmaisuusluettelo()
          ? t('elakkeetJaEtuudet')
          : t('saannollisetTulotHeading')}
      </Heading>

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('elakkeetJaEtuudetHeading')}
      </Heading>

      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['saannollisetTulot']
            ? getSaannollisetTulot(!!isPrincipalUnderaged, tili, t)
            : []
        }
      />

      {isPrincipalUnderaged &&
        tili.tulotiedot.saannollisetTulot.find(
          ({ tyyppi }) => tyyppi && AsiointiElaketyyppi.includes(tyyppi)
        ) && (
          <>
            <Heading variant="h4" mb={verticalMarginToken}>
              {t('elinkustannuksiinKaytettavatElaketulot')}
            </Heading>
            <YhteenvetoGroups
              groups={getAlaikaisenElinkustannuksiinKaytettavatElakkeet(
                tili,
                t
              )}
            />
          </>
        )}
    </section>
  )
})

export default SaannollisetTulot
