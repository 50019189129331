import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Block,
  Heading,
  suomifiDesignTokens,
  InlineAlert,
  Paragraph,
} from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const PerustiedotOsiot = styled.div<{ isTablet: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTablet }) => (isTablet ? '1fr 1fr' : 'auto')};
  gap: ${suomifiDesignTokens.spacing.m};
`

const getPerustiedotTranslations = (
  t: TFunction,
  tiliType: AsiointiType,
  alkupvm: string,
  loppupvm: string,
  maarapvm: string,
  sentDate?: string
) => {
  switch (tiliType) {
    case AsiointiType.OMAISUUSLUETTELO:
      return {
        henkilo: t('henkilo'),
        tilikausi: t('tehtavanAlkamispaiva'),
        tilikausipvm: `${alkupvm}`,
        lahetaViimeistaan: t('lahetaOmaisuusluetteloMennessa', {
          paivamaara: maarapvm,
        }),
        lahetetty: `${t('omaisuusluetteloOnLahetetty')} ${sentDate}`,
        valtakirjaHeading: t('omaisuusluetteloValtakirjaHeading'),
      }
    case AsiointiType.PAATOSTILI:
      return {
        henkilo: t('paamies'),
        tilikausi: t('tilikausi'),
        tilikausipvm: `${alkupvm} - ${loppupvm}`,
        lahetaViimeistaan: t('lahetaPaatostiliMennessa', {
          paivamaara: maarapvm,
        }),
        lahetetty: `${t('paatostiliOnLahetetty')} ${sentDate}`,
        valtakirjaHeading: t('paatostiliValtakirjaHeading'),
      }
    case AsiointiType.VUOSITILI:
      return {
        henkilo: t('henkilo'),
        tilikausi: t('tilikausi'),
        tilikausipvm: `${alkupvm} - ${loppupvm}`,
        lahetaViimeistaan: t('lahetaVuositiliMennessa', {
          paivamaara: maarapvm,
        }),
        lahetetty: `${t('vuositiliOnLahetetty')} ${sentDate}`,
        valtakirjaHeading: t('vuositiliValtakirjaHeading'),
      }
  }
}

const Perustiedot: React.FC<{ showValtakirja?: boolean }> = observer(
  ({ showValtakirja }) => {
    const [t] = useTranslation()

    const {
      vaatimus: {
        accountingPeriodStartDate,
        accountingPeriodEndDate,
        dueDate,
        guardianLastname,
        guardianFirstnames,
        principalLastname,
        principalFirstnames,
        asiaType,
        sentDate,
      },
      lomake: { tili },
    } = getTilintarkastusStore()
    const formattedAlkuPvm = IsoDatetimeToUiDate(accountingPeriodStartDate)
    const formattedLoppuPvm = IsoDatetimeToUiDate(accountingPeriodEndDate)
    const formattedMaaraPvm = IsoDatetimeToUiDate(dueDate)
    const formattedSentDate = sentDate ? IsoDatetimeToUiDate(sentDate) : ''
    const translations = getPerustiedotTranslations(
      t,
      asiaType,
      formattedAlkuPvm,
      formattedLoppuPvm,
      formattedMaaraPvm,
      formattedSentDate
    )
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    return (
      <Block
        mt={verticalMarginToken}
        data-test-id={mkYhteenvetoTestId('perustiedot')}
      >
        <Heading variant="h3">{t('perustiedot')}</Heading>
        <Block mt={verticalMarginToken} />
        <PerustiedotOsiot isTablet={isTablet}>
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-paamies-name')}
          >
            <Heading variant="h4">{translations.henkilo}</Heading>
            <Paragraph>
              {principalLastname}, {principalFirstnames}
            </Paragraph>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-tilikausi')}
          >
            <Heading variant="h4">{translations.tilikausi}</Heading>
            <Paragraph>{translations.tilikausipvm}</Paragraph>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId(
              'perustiedot-block-tilivelvollinen'
            )}
          >
            <Heading variant="h4">{t('tilivelvollinen')}</Heading>
            <Paragraph>
              {guardianLastname}, {guardianFirstnames}
            </Paragraph>
          </Block>
          {!!tili.edunvalvojanValtakirja.length && !!showValtakirja && (
            <Block
              data-test-id={mkYhteenvetoTestId('perustiedot-block-valtakirja')}
            >
              <Heading variant="h4">{translations.valtakirjaHeading}</Heading>
              <AttachmentList attachments={tili.edunvalvojanValtakirja} />
            </Block>
          )}
        </PerustiedotOsiot>
        <Block mt={verticalMarginToken} />
        <InlineAlert
          data-test-id={mkYhteenvetoTestId('lahetys-maarapaiva-text')}
        >
          <Paragraph>
            {sentDate ? translations.lahetetty : translations.lahetaViimeistaan}
          </Paragraph>
        </InlineAlert>
      </Block>
    )
  }
)

export default Perustiedot
