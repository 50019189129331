import React, { useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Button,
  IconArrowLeft,
  IconArrowRight,
  IconEdit,
  IconRemove,
  suomifiDesignTokens,
  VisuallyHidden,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import {
  getStore,
  initAsiointiStore,
  runAsiointiStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getNextStep,
  getPrevStep,
  LupaApplicationOtherView,
  LupaApplicationStep,
  pathByViewId,
  resolveStepByPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  mkFooterTestId,
  mkHakemusTestId,
  mkValidationErrorsSummaryTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import LupaTypeModal from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-type-select/LupaTypeModal'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { toggleSelectedLupaType } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/lupatype-actions'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { sendBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/batch-actions'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { centeredWidthLimited } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import SubmitBatchModal from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/footer/SubmitBatchModal'
import AbortDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/footer/AbortDialog'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'
import { getValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'

export type FooterPosition = 'static' | 'fixed'

const NavigationFooter: React.FC<{ position: FooterPosition }> = observer(
  ({ position }) => {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { batch, validationFailedForSteps } = getStore()
    const { user, lang } = useAsiointiUserStore()
    const selectedLupaTypeCount = batch.applications.length
    const valittuHenkilo = getValittuHenkilo()
    const asiointiStore = getStore()

    const currentStep = resolveStepByPath(location.pathname)

    const [isAbortDialogVisible, setAbortDialogVisible] = useState(false)
    const onAbort = async () => {
      setAbortDialogVisible(false)
      if (user) {
        await initAsiointiStore(
          user,
          lang,
          asiointiStore.countries,
          asiointiStore.productCatalog
        )
      }
      navigate(getAsioiPathByPersonId(valittuHenkilo?.personId))
    }

    const isPrevButtonVisible = () =>
      currentStep !== LupaApplicationStep.LUPA_TYPE_SELECT

    const onClickPrevButton = () => {
      const prevRouteId = getPrevStep(currentStep)
      if (prevRouteId) {
        navigate(pathByViewId[prevRouteId])
      }
    }

    const onSummaryPage = () => currentStep === LupaApplicationStep.SUMMARY
    const waitingForSubmit = () => batch.submitStatus === 'none'

    const onClickNextButton = () => {
      runAsiointiStoreAction((store) => {
        store.showValidationErrorsForSteps.add(currentStep)
      })

      if (validationFailedForSteps.has(currentStep)) {
        if (
          validationFailedForSteps.has(LupaApplicationStep.LUPA_TYPE_SELECT)
        ) {
          setTimeout(() => {
            runAsiointiStoreAction((store) => {
              if (currentStep !== LupaApplicationStep.LUPA_TYPE_SELECT) {
                navigate(pathByViewId[LupaApplicationStep.LUPA_TYPE_SELECT])
              }
              store.validationErrorFocus = mkHakemusTestId('alert-missing-lupa')
            })
          })
        } else {
          runAsiointiStoreAction((store) => {
            store.validationErrorFocus =
              mkValidationErrorsSummaryTestId('container')
          })
        }
      } else {
        const nextRouteId = getNextStep(currentStep)
        if (nextRouteId) {
          navigate(pathByViewId[nextRouteId])
        }
      }
    }

    const [isLupaTypeModalVisible, setLupaTypeModalVisible] = useState(false)
    const onChangeLupaType = () => {
      setLupaTypeModalVisible(false)
      if (currentStep !== LupaApplicationStep.LUPA_TYPE_SELECT) {
        navigate(pathByViewId[LupaApplicationStep.LUPA_TYPE_SELECT])
      } else {
        // autofocus logic does this automatically for above navigate-cases
        const headingElement = document.getElementById(
          mkHakemusTestId('current-step-container')
        )
        if (headingElement) {
          // Allow modal to close first and reset focus
          setTimeout(() => headingElement.focus(), 0)
        }
      }
    }
    const onRemoveLupaType = (lupaTypeId: AsiointiLupaTypeId) =>
      toggleSelectedLupaType(lupaTypeId, false)
    const modifySelectionButtonVisible = selectedLupaTypeCount > 0

    const [isSubmitBatchModalVisible, setConfirmSubmitBatchModalVisible] =
      useState(false)

    const displayValidationErrorsOnSummaryPage = () =>
      setTimeout(() => {
        runAsiointiStoreAction((store) => {
          if (!onSummaryPage()) {
            navigate(pathByViewId[LupaApplicationStep.SUMMARY])
          } else {
            setConfirmSubmitBatchModalVisible(false)
          }
          store.validationErrorFocus =
            mkValidationErrorsSummaryTestId('container')
        })
      })

    const openSubmitBatchModal = () => {
      if (validationFailedForSteps.size) {
        displayValidationErrorsOnSummaryPage()
      } else {
        setConfirmSubmitBatchModalVisible(true)
      }
    }

    const sendBatchAndNavigateToDone = async () => {
      if (batch.submitStatus === 'none') {
        const response = await sendBatch(lang)
        if (response.isOk) {
          navigate(pathByViewId[LupaApplicationOtherView.DONE])
        } else {
          setConfirmSubmitBatchModalVisible(false)
        }
      }
    }

    return (
      <>
        <LupaTypeModal
          visible={isLupaTypeModalVisible}
          lupaTypeIds={batch.applications.map(
            (application) => application.typeId
          )}
          onRemoveLupaType={onRemoveLupaType}
          onChangeLupaType={onChangeLupaType}
          onClose={() => {
            setLupaTypeModalVisible(false)
          }}
        />
        <SubmitBatchModal
          visible={isSubmitBatchModalVisible}
          onSubmit={sendBatchAndNavigateToDone}
          onClose={() => {
            setConfirmSubmitBatchModalVisible(false)
          }}
        />
        <AbortDialog
          visible={isAbortDialogVisible}
          onAbort={onAbort}
          onClose={() => {
            setAbortDialogVisible(false)
          }}
        />

        <FooterBackground
          id="lupa-navigation-footer"
          data-test-id={mkFooterTestId()}
          position={position}
        >
          <FooterContent $editButtonVisible={modifySelectionButtonVisible}>
            {modifySelectionButtonVisible && (
              <NavButton
                disabled={batch.submitStatus !== 'none'}
                onClick={() => setLupaTypeModalVisible(true)}
                variant="secondaryNoBorder"
                iconRight={<IconEdit />}
                data-test-id={mkFooterTestId('avaa-lupamodaali-painike')}
              >
                <span aria-live="polite" aria-atomic="true">
                  {t('hakemukselleLisattyLupaAsiaa', {
                    count: selectedLupaTypeCount,
                  })}
                </span>
                <VisuallyHidden aria-live="polite">
                  {', '}
                  {t('edit')}
                </VisuallyHidden>
              </NavButton>
            )}
            <VisuallyHidden
              aria-live="polite"
              data-test-id={mkFooterTestId('lupien-lkm-ruudunlukijalle')}
            >
              {t('hakemukselleLisattyLupaAsiaa', {
                count: selectedLupaTypeCount,
              })}
            </VisuallyHidden>

            <NavButtonGroup>
              <NavButton
                data-test-id={mkFooterTestId('avaa-keskeytamodaali-painike')}
                variant="secondaryLight"
                icon={<IconRemove />}
                onClick={() => setAbortDialogVisible(true)}
              >
                {t('keskeyta')}
              </NavButton>
              {isPrevButtonVisible() && waitingForSubmit() && (
                <NavButton
                  variant="secondary"
                  icon={<IconArrowLeft />}
                  onClick={onClickPrevButton}
                  data-test-id={mkFooterTestId('edellinen-painike')}
                >
                  {t('previous')}
                </NavButton>
              )}
              {!onSummaryPage() && (
                <NavButton
                  iconRight={<IconArrowRight />}
                  onClick={onClickNextButton}
                  data-test-id={mkFooterTestId('seuraava-painike')}
                >
                  {t('next')}
                </NavButton>
              )}
              {onSummaryPage() && waitingForSubmit() && (
                <NavButton
                  onClick={openSubmitBatchModal}
                  data-test-id={mkFooterTestId('laheta-painike')}
                >
                  {t('send')}
                </NavButton>
              )}
            </NavButtonGroup>
          </FooterContent>
        </FooterBackground>
      </>
    )
  }
)

export default NavigationFooter

const FooterBackground = styled.footer<{ position: 'fixed' | 'static' }>`
  width: 100%;
  position: ${({ position }) => position};
  bottom: 0;
  left: 0;
  background-color: ${suomifiDesignTokens.colors.whiteBase};
  box-shadow: 0 -4px 17px 0 rgba(0, 0, 0, 0.2);
`

const FooterContent = styled.div<{ $editButtonVisible?: boolean }>`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.xs} ${suomifiDesignTokens.spacing.xs};

  @media ${device.tablet} {
    padding-left: ${suomifiDesignTokens.spacing.l};
    padding-right: ${suomifiDesignTokens.spacing.l};
  }

  display: flex;
  gap: ${suomifiDesignTokens.spacing.xs};
  flex-flow: row wrap;
  justify-content: ${(props) =>
    props.$editButtonVisible ? 'space-between' : 'flex-end'};
`

const NavButtonGroup = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: ${suomifiDesignTokens.spacing.xs};
`

const NavButton = styled(Button)`
  padding: ${suomifiDesignTokens.spacing.xs};
  min-width: 100px;

  @media screen and ${device.tablet} {
    padding-inline: ${suomifiDesignTokens.spacing.m};
  }
`
