import { flow, observable } from 'mobx'
import * as draftApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/draft-api-client'
import {
  AsiointiDraft,
  InternalDraft,
  UpsertDraftRequest,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'

export type DraftStore = {
  drafts: Record<string, AsiointiDraft>
  isLoading: boolean
  isInitialized: boolean
}

const draftStore: DraftStore = observable({
  drafts: {},
  isLoading: false,
  isInitialized: false,
})

export function useDraftStore(): DraftStore {
  return draftStore
}

// Override local state by the state retrieved from the server
export const fetchDraftsIntoStore = flow(function* () {
  draftStore.isLoading = true
  const { drafts } = yield draftApi.getDrafts()
  draftStore.drafts = Object.fromEntries(
    drafts.map((draft: AsiointiDraft) => [draft.sourceId, draft])
  )
  draftStore.isInitialized = true
  draftStore.isLoading = false
  return draftStore.isInitialized
})

export const saveDraft = async ({
  sourceId,
  data,
  updatedAt,
  type,
}: UpsertDraftRequest & Pick<InternalDraft, 'updatedAt'>): Promise<void> =>
  flow(function* () {
    draftStore.drafts[sourceId] = { sourceId, updatedAt, type }
    draftStore.isLoading = true
    yield draftApi.saveTiliDraft({ sourceId, data, type })
    draftStore.isLoading = false
  })()
