import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import {
  InlineAlert,
  InlineAlertProps,
  suomifiDesignTokens,
} from 'suomifi-ui-components'

interface AsiointiInlineAlertProps {
  dataTestIdPrefix: string
  mode: 'error' | 'warning' | 'neutral' | 'tarkastusmerkinta'
  title?: string
}

const FormInlineAlert: React.FC<
  PropsWithChildren & InlineAlertProps & AsiointiInlineAlertProps
> = observer(({ children, dataTestIdPrefix, title, mode, mt, mb }) => {
  return (
    <CustomAlert
      labelText={title}
      status={mode === 'tarkastusmerkinta' ? 'neutral' : mode}
      ariaLiveMode="off"
      mt={mt}
      mb={mb}
      data-test-id={dataTestIdPrefix + '-inline-alert-' + mode}
      $tarkastusmerkinta={mode === 'tarkastusmerkinta'}
    >
      {children}
    </CustomAlert>
  )
})

export default FormInlineAlert

const tarkastusmerkintaColors = css`
  background: ${suomifiDesignTokens.colors.accentTertiaryLight1};
  padding: 5px 10px 5px 30px;
  border-color: ${suomifiDesignTokens.colors.accentTertiaryDark1};
`

const CustomAlert = styled(InlineAlert)<{ $tarkastusmerkinta?: boolean }>`
  &&& {
    ${(props) => props.$tarkastusmerkinta && tarkastusmerkintaColors}
  }
`
