import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getMuutMerkittavatToimet = (t: TFunction): YhteenvetoGroupProps[] => {
  const {
    vaatimus: { asiaType },
    lomake: {
      lomakkeelleValitutTiedot,
      tili: { muutMerkittavatToimet },
    },
  } = getTilintarkastusStore()

  const content: YhteenvetoGroupProps[] = [
    {
      heading: t(
        asiaType === 'OMAISUUSLUETTELO'
          ? 'onkoTulossaMuitaMerkittaviaToimia'
          : 'onkoMuitaMerkittaviaToimia'
      ),
      rows: [
        lomakkeelleValitutTiedot.muutMerkittavatToimet !== undefined
          ? lomakkeelleValitutTiedot.muutMerkittavatToimet
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (lomakkeelleValitutTiedot.muutMerkittavatToimet) {
    content.push({
      heading: t('mitaMuitaMerkittaviaToimia'),
      rows: [muutMerkittavatToimet],
    })
  }

  return content
}

const MuutMerkittavatToimet: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section
      data-test-id={mkYhteenvetoTestId('muut-merkittavat-toimet-container')}
    >
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('muutMerkittavatToimet')}
      </Heading>
      <YhteenvetoGroups groups={getMuutMerkittavatToimet(t)} />
    </section>
  )
})

export default MuutMerkittavatToimet
