import { MimeType } from 'common/src/vtj/MimeType.enum'
import { flow, observable } from 'mobx'
import {
  AsiointiFileAttachment,
  AsiointiFileAttachmentError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import {
  pollFileChecks,
  stopPollingFileChecks,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file-attachment-check'
import {
  AsiakirjaCreateData,
  uploadFile,
  getValidationStatuses,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/file-api-client'
import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { FrontAsiakirjaLiite } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'

type UploadAttachmentProps = {
  observableAttachments: AsiointiFileAttachment[]
  file: File
  asiakirjaTypeId: string
}
export const uploadAttachment = ({
  observableAttachments,
  file,
  asiakirjaTypeId,
}: UploadAttachmentProps): Promise<void> => {
  return flow(function* () {
    const observableAttachment = yield observable({
      sourceFileId: crypto.randomUUID(),
      mimeType: file.type as MimeType,
      filename: file.name,
      sizeBytes: file.size,
      status: 'loading',
      asiakirjatyyppi: asiakirjaTypeId,
    } as FrontAsiakirjaLiite)
    observableAttachments.push(observableAttachment)

    const response: ApiResponse<AsiakirjaCreateData> = yield uploadFile({
      sourceFileId: observableAttachment.sourceFileId,
      file: file,
    })
    if (response.isOk) {
      observableAttachment.status = 'checking'
      observableAttachment.asiakirjaId = response.data.asiakirjaId
      pollFileChecks({ getValidationStatuses }, observableAttachment)
    } else {
      const status: AsiointiFileAttachment['status'] = 'failed'
      const error: AsiointiFileAttachmentError = 'upload_error'
      Object.assign(observableAttachment, { status, error })
    }
    return yield observableAttachment
  })()
}

export const createFailedAttachment = (
  fileProps: UploadAttachmentProps & {
    status: 'failed'
    error: AsiointiFileAttachmentError
  }
): Promise<void> => {
  return flow(function* () {
    const observableAttachment = observable({
      sourceFileId: crypto.randomUUID(),
      mimeType: fileProps.file.type as MimeType,
      filename: fileProps.file.name,
      sizeBytes: fileProps.file.size,
      status: fileProps.status,
      error: fileProps.error,
    })
    fileProps.observableAttachments.push(observableAttachment)
  })()
}

export const removeAttachment = (
  sourceFileId: AsiointiFileAttachment['sourceFileId']
): void => stopPollingFileChecks(sourceFileId)
