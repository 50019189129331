import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import OmassaKaytossaOlevaOmaisuusForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/OmassaKaytossaOlevaOmaisuusForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import PankkitilitForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/OmassaKaytossaPankkitilitForm'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'

const OmassaKaytossaOmaisuusPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock
        data-test-id={mkYleiskatsausTestId(
          'omassaKaytossaOmaisuusPage-container'
        )}
      >
        <PageStepHeading
          heading={t('kaytossaOlevaOmaisuusHeading')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph>{t('kaytossaOlevaOmaisuusInfo1')}</Paragraph>
        <Paragraph mt="s">{t('kaytossaOlevaOmaisuusInfo2')}</Paragraph>
        <VahvistaEsitaytetytTiedot
          tiedot={['pankkitilit', 'omaisuusOmassaKaytossa']}
          dataTestId={mkYleiskatsausTestId(
            'omassaKaytossaOmaisuusPage-isTarkistettu'
          )}
        >
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <PankkitilitForm />

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <OmassaKaytossaOlevaOmaisuusForm />

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </VahvistaEsitaytetytTiedot>

        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default OmassaKaytossaOmaisuusPage
