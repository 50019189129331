import React from 'react'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { TFunction, useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

export const getVerotiedot = (
  { verotustiedot }: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  return verotustiedot.length
    ? [
        {
          heading: t('veroIlmoitusTaiVerotuspaatosTuloverosta'),
          rows: [],
          attachments: verotustiedot,
        },
      ]
    : []
}

const Verotiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('verotiedot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('verotiedot')}
      </Heading>
      <YhteenvetoGroups groups={getVerotiedot(tili, t)} />
    </section>
  )
})

export default Verotiedot
