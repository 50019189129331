import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const getContent = (t: TFunction): YhteenvetoGroupProps[] => {
  const content = []
  const {
    lomake: {
      lomakkeelleValitutTiedot,
      tili: {
        paamiehenOmaisuus: { muutVarat },
      },
    },
  } = getTilintarkastusStore()
  content.push({
    heading: t('saakoHenkiloOmaanKayttoonsaMuitaVaroja'),
    rows: [
      lomakkeelleValitutTiedot.muutOmassaKaytossaOlevatVarat
        ? t('kylla')
        : lomakkeelleValitutTiedot.muutOmassaKaytossaOlevatVarat === false
        ? t('ei')
        : undefined,
    ],
  })
  if (lomakkeelleValitutTiedot.muutOmassaKaytossaOlevatVarat) {
    content.push({
      heading: t('mitaMuitaVarojaHenkiloSaaOmaanKayttoon'),
      rows: [muutVarat],
    })
  }
  return content
}

const MuutVarat: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('muutOmassaKaytossaOlevatVarat')}
      </Heading>
      <YhteenvetoGroups groups={getContent(t)} />
    </>
  )
})

export default MuutVarat
