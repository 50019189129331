import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  Text,
} from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTilirivit,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  AsiointiType,
  FrontTalletus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonIbanValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import {
  formatNumberWithComma,
  formatTilinumeroInput,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { translateAssetType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/varat-util'
import { AsiointiTalletustyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import styled from 'styled-components'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const PankkiTalletuksetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const tilirivit = getTilirivit()
  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  const mkNewEntry = (): FrontTalletus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      myontajanNimi: '',
      vakuusTaiTilinumero: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      yhteiskayttotili: undefined,
      kokonaissaldoAlussa: undefined,
      kokonaissaldoLopussa: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('talletukset-form')}>
      <Heading variant="h3" mb="xs">
        {t('talletuksetHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaTalletukset')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={TalletusListItem}
        ModalContentComponent={TalletusModal}
        state={tilirivit.varat.talletukset}
        dataTestIdPrefix={mkOmaisuusTestId('talletukset-form')}
        newEntry={mkNewEntry}
        translationPrefix="talletukset"
        stateSubmitAdapter={({
          kokonaissaldoAlussa,
          kokonaissaldoLopussa,
          yhteiskayttotili,
          ...rest
        }: FrontTalletus) => {
          if (yhteiskayttotili) {
            return {
              kokonaissaldoAlussa,
              kokonaissaldoLopussa,
              yhteiskayttotili,
              ...rest,
            }
          } else {
            return { yhteiskayttotili, ...rest }
          }
        }}
        tiliLomakeKentta={TiliLomakeKentta.talletukset}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
      {lomakkeelleValitutTiedot['talletukset'] === false && (
        <InlineAlert mt={verticalMarginToken}>
          {t('tarvisenPankkitilinInlineAlert')}
        </InlineAlert>
      )}
    </section>
  )
})

const TalletusModal: FC<FormModalProps<FrontTalletus>> = observer(
  ({ createDataTestId, entry, setEntry, isEditing }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const {
      vaatimus: { asiaType },
    } = getTilintarkastusStore()

    const translationKeys = talletusTranslations(asiaType, entry.tyyppi)
    const tilityypit = getTalletustyypit.map(translateAssetType(t))
    const { vaatimus: evtv } = getTilintarkastusStore()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)

    const attachmentRequired =
      evtv.asiaType !== 'OMAISUUSLUETTELO' &&
      (!isEditing || !!entry.asiakirjat.length)

    return (
      <>
        <FormDropdown
          labelText={t('valitseTilityyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={tilityypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountAssetsType,
              vakuusTaiTilinumero:
                value === AsiointiLedgerAccountAssetsType.VAKUUTUSSAASTOTILI
                  ? entry.vakuusTaiTilinumero
                  : formatTilinumeroInput(entry.vakuusTaiTilinumero),
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormCheckboxInput
          data-test-id={createDataTestId('modal-yhteiskayttotili-checkbox')}
          labelText={t('tamaOnYhteiskayttoTili')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              yhteiskayttotili: value,
            })
          }}
          defaultValue={entry.yhteiskayttotili}
        />
        <Block mt={verticalMarginToken} />

        {entry.yhteiskayttotili && (
          <>
            <BreakAwareInlineAlert
              data-test-id={createDataTestId('yhteiskayttotili-alert')}
            >
              {t('yhteiskayttoTiliInfo')}
            </BreakAwareInlineAlert>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.tyyppi === AsiointiLedgerAccountType.VAKUUTUSSAASTOTILI ? (
          <FormTextInput
            data-test-id={createDataTestId('modal-vakuusTaiTilinumero')}
            labelText={t(translationKeys.vakuusTaiTilinumero)}
            value={entry.vakuusTaiTilinumero}
            required
            validate={commonXssValidator(t)}
            updateValue={(value) =>
              setEntry({
                ...entry,
                vakuusTaiTilinumero: value,
              })
            }
          />
        ) : (
          <FormTextInput
            data-test-id={createDataTestId('modal-vakuusTaiTilinumero')}
            labelText={t(translationKeys.vakuusTaiTilinumero)}
            value={formatTilinumeroInput(entry.vakuusTaiTilinumero)}
            hintText={t('kirjoitaIbanMuodossa')}
            required
            validate={commonIbanValidator(t)}
            updateValue={(value) =>
              setEntry({
                ...entry,
                vakuusTaiTilinumero: formatTilinumeroInput(value),
              })
            }
          />
        )}

        <FormTextInput
          data-test-id={createDataTestId('modal-myontajanNimi')}
          labelText={t(translationKeys.myontajanNimi)}
          value={entry.myontajanNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, myontajanNimi: value })}
        />

        {entry.yhteiskayttotili && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-yhteiskayttoinenTiliSaldo')}
            labelText={t('yhteiskayttoinenTiliSaldo')}
            hintText={
              isOmaisuusluettelo()
                ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
                : undefined
            }
            required
            value={entry.kokonaissaldoLopussa}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) => {
              setEntry({
                ...entry,
                kokonaissaldoLopussa: value,
              })
            }}
          />
        )}

        {!isOmaisuusluettelo() && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t(
              entry.yhteiskayttotili
                ? 'henkilonOsuusEuroinaAlussa'
                : 'arvoTilikaudenAlussaEuroina'
            )}
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(
            entry.yhteiskayttotili
              ? translationKeys.yhteiskaytossa
              : translationKeys.arvoLopussa
          )}
          hintText={
            isOmaisuusluettelo()
              ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
              : undefined
          }
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          attachmentsGroupId={entry.id}
          observableAttachments={entry.asiakirjat}
          required={attachmentRequired}
          asiakirjaTypeId={TiliAsiakirjaType.TILIOTE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translationKeys.tiliotteetTaiSopimukset)}
          title={t(translationKeys.asiakirjat)}
          overrideRequiredCheckboxLabel={t(
            translationKeys.asiakirjatToimitettu
          )}
        />
      </>
    )
  }
)

const TalletusListItem: FC<FormElementProps<FrontTalletus>> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    const {
      vaatimus: { asiaType },
    } = getTilintarkastusStore()
    const translationKeys = talletusTranslations(asiaType, entry.tyyppi)
    const arvoLopussaEur =
      entry.arvoTilikaudenLopussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
        : undefined

    const arvoAlussaEur =
      entry.arvoTilikaudenAlussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
        : ''

    const kokonaissaldo =
      entry.kokonaissaldoLopussa !== undefined
        ? `${formatNumberWithComma(entry.kokonaissaldoLopussa)} €`
        : ''

    const showAlert = entry.arvoTilikaudenLopussaEur === undefined

    return (
      <FormListInputExpander
        heading={`${t(translationKeys.tilityyppi)} ${entry.myontajanNimi}`}
        headingRight={arvoLopussaEur}
        subHeading={entry.vakuusTaiTilinumero}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        pakollinenTietoPuuttuu={showAlert}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        <Heading variant="h5">{t(translationKeys.vakuusTaiTilinumero)}</Heading>
        <Text>{entry.vakuusTaiTilinumero}</Text>
        <Block mt={verticalMarginToken} />

        {entry.yhteiskayttotili && (
          <>
            <Heading variant="h5">{t('yhteiskayttoTili')}</Heading>
            <Text>{t('yes')}</Text>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('yhteiskayttoinenTiliSaldo')}</Heading>
            <Text>{kokonaissaldo}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {!isOmaisuusluettelo() && (
          <>
            <Heading variant="h5">
              {t(
                entry.yhteiskayttotili
                  ? 'henkilonOsuusEuroinaAlussa'
                  : 'arvoTilikaudenAlussaEuroina'
              )}
            </Heading>
            <Text>{arvoAlussaEur}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t(
            entry.yhteiskayttotili
              ? translationKeys.yhteiskaytossa
              : translationKeys.arvoLopussa
          )}
          content={arvoLopussaEur}
        />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t(translationKeys.asiakirjat)}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

const talletusTranslations = (
  asiointiType: AsiointiType,
  tyyppi?: AsiointiLedgerAccountAssetsType
) => {
  const tilityyppi = `tilityyppi_${tyyppi}`

  if (tyyppi === AsiointiLedgerAccountAssetsType.VAKUUTUSSAASTOTILI) {
    return {
      ...talletusCommonTranslations[asiointiType].saldoLopussa,
      myontajanNimi: 'vakuutusYhtionNimi',
      vakuusTaiTilinumero: 'vakuutusNumero',
      asiakirjat: 'tiliotteetJaSopimukset',
      ...talletusCommonTranslations[asiointiType].asiakirjaInfo,
      tilityyppi,
    }
  }
  return {
    ...talletusCommonTranslations[asiointiType].saldoLopussa,
    myontajanNimi: 'pankinNimi',
    vakuusTaiTilinumero: 'tilinumero',
    asiakirjat: 'tiliotteet',
    ...talletusCommonTranslations[asiointiType].asiakirjaInfo,
    tilityyppi,
  }
}

const talletusCommonTranslations = {
  OMAISUUSLUETTELO: {
    saldoLopussa: {
      yhteiskaytossa: 'yhteiskayttoinenTiliOsuus',
      arvoLopussa: 'arvoEuroina',
    },
    asiakirjaInfo: {
      tiliotteetTaiSopimukset: 'asiakirjatTilioteSopimuksetTehtavanAlussa',
      asiakirjatToimitettu: '',
    },
  },
  VUOSITILI: {
    saldoLopussa: {
      yhteiskaytossa: 'henkilonOsuusEuroinaLopussa',
      arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    },
    asiakirjaInfo: {
      tiliotteetTaiSopimukset: 'tiliotteetJaSopimuksetInfo',
      asiakirjatToimitettu: 'liiteJoToimitettuVuositili',
    },
  },
  PAATOSTILI: {
    saldoLopussa: {
      yhteiskaytossa: 'henkilonOsuusEuroinaLopussa',
      arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    },
    asiakirjaInfo: {
      tiliotteetTaiSopimukset: 'tiliotteetJaSopimuksetInfo',
      asiakirjatToimitettu: 'liiteJoToimitettuPaatostili',
    },
  },
}

const getTalletustyypit = Object.values(AsiointiTalletustyyppi).map(
  (talletustyyppi) => ({
    name: `tilityyppi_${talletustyyppi}`,
    value: talletustyyppi,
  })
)

const BreakAwareInlineAlert = styled(InlineAlert)`
  .fi-inline-alert_content {
    white-space: pre-line;
  }
`

export default PankkiTalletuksetForm
