import React, { useEffect, useId, useState } from 'react'
import { AsiointiFileAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import { observer } from 'mobx-react'
import { FileRejection } from 'react-dropzone'
import {
  createFailedAttachment,
  removeAttachment,
  uploadAttachment,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/actions/file-attachment-actions'
import FileBox, {
  FileBoxFile,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileBox'
import { TFunction, useTranslation } from 'react-i18next'
import { lupaAsiointiFileApi } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/file-api'
import { getErrorMessage } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file.util'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import * as FileUtil from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file.util'
import settingsSingleton from 'asia-common/src/vtj/settings'
import { reaction, runInAction } from 'mobx'
import { Controller, useFormContext } from 'react-hook-form'
import { getDownloadUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/file-api-client'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, Label, Text } from 'suomifi-ui-components'
import { lowerFirst } from 'lodash'
import { LupaAsiakirjaTypeId } from 'lupa-backend/src/vtj/elsa/asiakirja/lupa-asiakirja-enums'
import { TiliAsiakirjaTypeId } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'

const FormAttachmentFileBox: React.FC<{
  attachmentsGroupId?: string
  observableAttachments: AsiointiFileAttachment[]
  required?: boolean
  'data-test-id': string
  title?: string
  hideTitle?: boolean
  text?: string
  asiakirjaTypeId: LupaAsiakirjaTypeId | TiliAsiakirjaTypeId
  overrideRequiredCheckboxLabel?: string
}> = observer(
  ({
    attachmentsGroupId,
    observableAttachments,
    required = false,
    'data-test-id': dataTestId,
    title,
    hideTitle = false,
    text,
    asiakirjaTypeId,
    overrideRequiredCheckboxLabel,
  }) => {
    if (!dataTestId) {
      throw new Error('Missing data test id')
    }
    const [t] = useTranslation()
    const {
      formState: { errors },
      resetField,
    } = useFormContext()
    const [currentAttachmentsGroupId, setCurrentAttachmentsGroupId] = useState<
      undefined | string
    >(errors[dataTestId] ? attachmentsGroupId : undefined)

    const fileBoxId = useId()
    const lisaaErrorMessage = `${t('lisaa')} ${lowerFirst(title)}`

    const [requiredState, setRequiredState] = useState(required)

    useEffect(() => {
      // Reset form validation status and value when attachments group id changes
      if (
        attachmentsGroupId !== undefined &&
        currentAttachmentsGroupId !== attachmentsGroupId
      ) {
        resetField(dataTestId, { defaultValue: observableAttachments })
        setCurrentAttachmentsGroupId(attachmentsGroupId)
      }
    }, [
      attachmentsGroupId,
      setCurrentAttachmentsGroupId,
      currentAttachmentsGroupId,
      dataTestId,
      resetField,
      observableAttachments,
    ])

    return (
      <>
        <Controller
          defaultValue={observableAttachments}
          name={dataTestId}
          render={({ field: { onChange, ref } }) => {
            // Trigger an update everytime when attachment status changes
            reaction(
              () => observableAttachments.map(({ status }) => status),
              () => onChange(observableAttachments)
            )
            return (
              <>
                {title && !hideTitle && (
                  <div>
                    <Label htmlFor={fileBoxId}>
                      {title}{' '}
                      {!requiredState && (
                        <Text smallScreen>
                          &nbsp;
                          {'(' + t('valinnainen') + ')'}
                        </Text>
                      )}
                    </Label>
                  </div>
                )}
                {text && (
                  <>
                    <Block mt="xs" />
                    <Text smallScreen>{text}</Text>
                  </>
                )}
                <Block mt="s" />
                <FocusableDiv tabIndex={-1} ref={ref}>
                  <AttachmentFileBox
                    observableAttachments={observableAttachments}
                    asiakirjaTypeId={asiakirjaTypeId}
                    htmlId={fileBoxId}
                    data-test-id={dataTestId}
                    errorMessage={(errors[dataTestId]?.message as string) || ''}
                  />
                </FocusableDiv>
              </>
            )
          }}
          rules={{
            validate: (value: AsiointiFileAttachment[]) => {
              if (!value.length && requiredState) {
                return lisaaErrorMessage
              } else if (value.find((item) => item.status !== 'success')) {
                return `${lisaaErrorMessage} ${lowerFirst(
                  t('tiedostoEiOleVielaValmis')
                )}`
              } else {
                return true
              }
            },
          }}
        />
        {overrideRequiredCheckboxLabel && (
          <Block mt="xl">
            <FormCheckboxInput
              data-test-id={dataTestId + '-required-checkbox'}
              labelText={overrideRequiredCheckboxLabel}
              updateValue={(value) => {
                setRequiredState(!value)
                resetField(dataTestId)
              }}
              defaultValue={!(observableAttachments.length || requiredState)}
              disabled={observableAttachments.length > 0}
            />
          </Block>
        )}
      </>
    )
  }
)

const attachmentToFileBoxFile = (
  t: TFunction,
  ak: AsiointiFileAttachment
): FileBoxFile => ({
  id: ak.sourceFileId,
  name: ak.filename,
  mimeType: ak.mimeType,
  sizeBytes: ak.sizeBytes,
  status: ak.status === 'checking' ? 'loading' : ak.status,
  statusText: ak.status === 'checking' ? t('tiedostoaTarkistetaan') : undefined,
  isRemovable: true,
  downloadHref:
    ak.status === 'success'
      ? ak.asiakirjaId
        ? getDownloadUrl(ak)
        : lupaAsiointiFileApi.getDownloadUrl(ak)
      : undefined,
  errorMessage:
    ak.status === 'failed' ? getErrorMessage(t, ak.error) : undefined,
})

const AttachmentFileBox: React.FC<{
  observableAttachments: AsiointiFileAttachment[]
  asiakirjaTypeId: LupaAsiakirjaTypeId | TiliAsiakirjaTypeId
  htmlId: string
  'data-test-id': string
  errorMessage: string
}> = observer(
  ({
    observableAttachments,
    asiakirjaTypeId,
    htmlId,
    'data-test-id': dataTestId,
    errorMessage,
  }) => {
    const onAddFiles = async (
      acceptedFiles: File[],
      rejectedFiles: FileRejection[]
    ) => {
      for (const file of acceptedFiles) {
        await uploadAttachment({
          observableAttachments,
          file,
          asiakirjaTypeId: asiakirjaTypeId,
        })
      }
      for (const file of rejectedFiles) {
        await createFailedAttachment({
          observableAttachments,
          asiakirjaTypeId: asiakirjaTypeId,
          status: 'failed',
          error: FileUtil.getError(file),
          file: file.file,
        })
      }
    }
    const onRemoveFile = (file: FileBoxFile) => {
      removeAttachment(file.id)
      const index = observableAttachments.findIndex(
        ({ sourceFileId }: AsiointiFileAttachment) => sourceFileId === file.id
      )
      if (index >= 0) {
        runInAction(() => {
          observableAttachments.splice(index, 1)
        })
      }
    }

    const [t] = useTranslation()
    const fileBoxFiles: FileBoxFile[] = observableAttachments.map((item) => {
      return attachmentToFileBoxFile(t, item)
    })

    return (
      <FileBox
        errorMessage={errorMessage}
        htmlId={htmlId}
        files={fileBoxFiles}
        onAdd={onAddFiles}
        onRemove={onRemoveFile}
        minFileSizeBytes={1}
        maxFileSizeBytes={
          settingsSingleton.get(
            'asiointiServer.attachmentLimits.maxSizeInBytes'
          ) as number
        }
        allowedMimeTypes={
          settingsSingleton.get(
            'asiointiServer.attachmentLimits.allowedMimeTypes'
          ) as MimeType[]
        }
        data-test-id={dataTestId}
      />
    )
  }
)

export default FormAttachmentFileBox
