import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Heading } from 'suomifi-ui-components'
import TiliStatusChip from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/TiliStatusChip'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

export const TopHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
`

export const TopHeading: React.FC<{
  children: React.ReactNode
}> = observer(({ children }) => {
  const { vaatimus, draft } = getTilintarkastusStore()
  return (
    <TopHeadingContainer>
      <Heading variant="h1">{children}</Heading>
      {draft && !vaatimus.sentDate && (
        <TiliStatusChip vaatimus={vaatimus} draft={draft} />
      )}
    </TopHeadingContainer>
  )
})
