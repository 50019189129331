import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumat20VuottaRetention,
  elamantapahtumatPysyvaRetention,
  elamantapahtumatSalassaPidettava100VuottaK32EiHenkilotietojaRestriction,
  elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

export const lapsenRekistrointiKotimainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_SYNTYMASTA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['892ccc28-bad1-4890-b838-d0a77be16fb2'],
      defaultToivoAsiakirjaTypeId: '892ccc28-bad1-4890-b838-d0a77be16fb2',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_SYNTYMASTA_HETUTON,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat20VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['da16dd64-2881-4c59-a828-b08fb4d51948'],
      defaultToivoAsiakirjaTypeId: 'da16dd64-2881-4c59-a828-b08fb4d51948',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_LAPSEN_TIEDOISTA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['eaec17bc-94b7-4beb-9908-840b58ac8296'],
      defaultToivoAsiakirjaTypeId: 'eaec17bc-94b7-4beb-9908-840b58ac8296',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_LAPSEN_TIETOJEN_ILMOITTAMINEN,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['06859e06-aa87-44bf-90b0-bba6c12f2bff'],
      defaultToivoAsiakirjaTypeId: '06859e06-aa87-44bf-90b0-bba6c12f2bff',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['cd05f19f-ec35-43c4-8e91-eff6876f0782'],
      defaultToivoAsiakirjaTypeId: 'cd05f19f-ec35-43c4-8e91-eff6876f0782',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SEURANKUNNAN_ILMOITUS_LAPSEN_TIEDOISTA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e9fb2a5f-d48e-48e6-81f7-4333f6095f74'],
      defaultToivoAsiakirjaTypeId: 'e9fb2a5f-d48e-48e6-81f7-4333f6095f74',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_LAPSEN_TIETOJEN_ILMOITTAMINEN_VAHVISTUS,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['591606b5-1904-4193-b150-165bdb71c2d3'],
      defaultToivoAsiakirjaTypeId: '591606b5-1904-4193-b150-165bdb71c2d3',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['725249c7-66fe-46c8-8929-5b21a5fc2d70'],
      defaultToivoAsiakirjaTypeId: '725249c7-66fe-46c8-8929-5b21a5fc2d70',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_TAYDENNYS,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['023236ee-a080-4d86-810d-69ea26edc15c'],
      defaultToivoAsiakirjaTypeId: '023236ee-a080-4d86-810d-69ea26edc15c',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_TAYDENNYKSEN_VAHVISTUS,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5da7a35e-af87-4a32-aa9b-481d918020a3'],
      defaultToivoAsiakirjaTypeId: '5da7a35e-af87-4a32-aa9b-481d918020a3',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.DVVN_PAATOS_LIITTEINEEN,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['b0cd396b-027f-483d-870d-8da7834d1192'],
      defaultToivoAsiakirjaTypeId: 'b0cd396b-027f-483d-870d-8da7834d1192',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32EiHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['f84144de-cad4-4cf8-91d5-d5267e5cee43'],
      defaultToivoAsiakirjaTypeId: 'f84144de-cad4-4cf8-91d5-d5267e5cee43',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_JATKOKASITTELYA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SUOSTUMUSTA,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.LAPSI],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: true,
    specifierTypeIds: [SpecifierType.HENKILOTUNNUKSEN_ANTAMINEN, SpecifierType.LAPSEN_NIMI_AIDINKIELI_JA_KANSALAISUUS],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_SELVITYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.SELVITYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: false,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.ELOMAKE_KANSALAINEN],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
