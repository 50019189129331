import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontTyottomyysturva } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { AsiointiTuloTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import { AsiointiLedgerAccountSubCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const TyottomyysturvatulotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const tilirivit = getTilirivit()

  const mkNewElement = (): FrontTyottomyysturva =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      maksaja: '',
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('tyottomyysturvatulot-form')}>
      <Heading variant="h3" mb="xs">
        {t('tyottomyysturvatulotHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaTyottomyysturvatulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={TyottomyysturvatuloListItem}
        state={tilirivit.tulot.tyottomyysturva}
        dataTestIdPrefix={mkTulotMenotTestId('tyottomyysturvatulot-form')}
        ModalContentComponent={TyottomyysturvatuloModal}
        newEntry={mkNewElement}
        translationPrefix="tyottomyysturvatulot"
        tiliLomakeKentta={TiliLomakeKentta.tyottomyysturva}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const TyottomyysturvatuloModal: FC<FormModalProps<FrontTyottomyysturva>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const tyottomyysturvatyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiTuloTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA
            )
          ).map((value) => ({
            name: t(`incomeType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('tyottomyysturvatulonTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={tyottomyysturvatyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountIncomeType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormTextInput
          data-test-id={createDataTestId('modal-maksaja')}
          labelText={t('maksaja')}
          value={entry.maksaja}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, maksaja: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-tulotYhteensa')}
          labelText={t('tulotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  })

const TyottomyysturvatuloListItem: FC<FormElementProps<FrontTyottomyysturva>> =
  observer(
    ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
      const [t] = useTranslation()
      const isTablet = useDeviceContext().tablet
      const verticalMarginToken = isTablet ? 'm' : 's'

      const yhteensaEur =
        entry.yhteensa !== undefined
          ? `${formatNumberWithComma(entry.yhteensa)} €`
          : undefined

      return (
        <FormListInputExpander
          heading={t(`incomeType_${entry.tyyppi}`)}
          headingRight={yhteensaEur}
          createDataTestId={createDataTestId}
          onEdit={onEdit}
          onRemove={onRemove}
          pakollinenTietoPuuttuu={entry.yhteensa === undefined}
          tiliLomakeKentta={tiliLomakeKentta}
        >
          <Heading variant="h5">{t('maksaja')}</Heading>
          <Text>{entry.maksaja}</Text>
          <Block mt={verticalMarginToken} />

          <ContentItemOrPakollinenTietoPuuttuuWarning
            heading={t('tulotTilikaudenAikanaYhteensaEuroina')}
            content={yhteensaEur}
          />
        </FormListInputExpander>
      )
    }
  )

export default TyottomyysturvatulotForm
