import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTiedoksiantoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Heading, Paragraph } from 'suomifi-ui-components'
import {
  commonEmailValidator,
  commonHetuValidator,
  commonPhoneValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { BlueBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import FormCountryDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCountryDropdown'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { action } from 'mobx'
import { COUNTRY_CODE_FINLAND } from 'asia-common/src/vtj/country/country.util'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/country.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { TiliPersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'

const TiedoksiantoForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { lang } = useAsiointiUserStore()

  const {
    lomake: { tiedoksiannettava, lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()

  if (!tiedoksiannettava) {
    return undefined // For lint happiness
  }

  const etunimetTestId = mkTiedoksiantoTestId('etunimet')
  const sukunimiTestId = mkTiedoksiantoTestId('sukunimi')
  const hetuTestId = mkTiedoksiantoTestId('hetu')
  const phoneTestId = mkTiedoksiantoTestId('phone')
  const emailTestId = mkTiedoksiantoTestId('email')
  const katuosoiteTestId = mkTiedoksiantoTestId('katuosoite')
  const postinumeroTestId = mkTiedoksiantoTestId('postinumero')
  const postitoimipaikkaTestId = mkTiedoksiantoTestId('postitoimipaikka')
  const maaTestId = mkTiedoksiantoTestId('maa')
  const countryId = tiedoksiannettava?.address.countryId || COUNTRY_CODE_FINLAND
  const countryName = getCountryById(countryId)?.shortName[lang]

  return (
    <section data-test-id={mkTiedoksiantoTestId('form')}>
      <Heading variant="h3" as="h2" mb={verticalMarginToken}>
        {t('tiedoksianto')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>{t('ohjeTiedoksianto')}</Paragraph>

      <FormRadioButtonGroup
        required
        value={(!lomakkeelleValitutTiedot.tiedoksiannettava).toString()}
        updateValue={action((value) => {
          lomakkeelleValitutTiedot.tiedoksiannettava = value !== 'true'
        })}
        data-test-id={mkTiedoksiantoTestId('ainoa-osakas')}
        labelText={t('oletkoKuolinpesanAinoaOsakas')}
        radioButtons={[
          { value: 'false', labelText: t('enOle') },
          { value: 'true', labelText: t('olen') },
        ]}
      />

      {lomakkeelleValitutTiedot.tiedoksiannettava && (
        <BlueBorderedContentBlock padding="m">
          <Heading variant="h3" as="h2" mb={isTablet ? 'm' : 'xs'}>
            {t('kuolinpesanSaajanYhteystiedot')}
          </Heading>
          <Paragraph mb={verticalMarginToken}>
            {t('ohjeIlmoitaOsakkaanYhteystiedotTiedoksiantoon')}
          </Paragraph>

          <FormRadioButtonGroup
            required
            value={tiedoksiannettava.henkiloTyyppi}
            updateValue={action(
              (role) =>
                (tiedoksiannettava.henkiloTyyppi =
                  role === TiliPersonRole.YRITYS
                    ? TiliPersonRole.YRITYS
                    : TiliPersonRole.YKSITYISHENKILO)
            )}
            data-test-id={mkTiedoksiantoTestId('vastaanottaja-tyyppi')}
            labelText={t('vastaanottajanTyyppi')}
            radioButtons={[
              {
                value: TiliPersonRole.YKSITYISHENKILO,
                labelText: t('henkilo'),
              },
              {
                value: TiliPersonRole.YRITYS,
                labelText: t('yritysTaiYhteiso'),
              },
            ]}
          />
          {tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YRITYS && (
            <>
              <FormTextInput
                data-test-id={mkTiedoksiantoTestId('yritys-nimi')}
                labelText={t('yrityksenNimi')}
                value={tiedoksiannettava.businessName}
                required
                validate={commonXssValidator(t)}
                updateValue={action(
                  (value) => (tiedoksiannettava.businessName = value)
                )}
              />
              <FormTextInput
                data-test-id={mkTiedoksiantoTestId('yritys-ytunnus')}
                labelText={t('yTunnus')}
                value={tiedoksiannettava.businessId}
                validate={commonXssValidator(t)}
                updateValue={action(
                  (value) => (tiedoksiannettava.businessId = value)
                )}
              />
            </>
          )}
          {tiedoksiannettava.henkiloTyyppi ===
            TiliPersonRole.YKSITYISHENKILO && (
            <>
              <FormTextInput
                data-test-id={etunimetTestId}
                labelText={t('etunimet')}
                value={tiedoksiannettava.firstnames || ''}
                required
                validate={commonXssValidator(t)}
                updateValue={action(
                  (value) => (tiedoksiannettava.firstnames = value)
                )}
              />
              <FormTextInput
                data-test-id={sukunimiTestId}
                labelText={t('sukunimi')}
                value={tiedoksiannettava.lastname || ''}
                required
                validate={commonXssValidator(t)}
                updateValue={action(
                  (value) => (tiedoksiannettava.lastname = value)
                )}
              />
              <FormTextInput
                data-test-id={hetuTestId}
                labelText={t('henkilotunnus')}
                defaultValue={tiedoksiannettava.hetu || ''}
                validate={commonHetuValidator(t)}
                updateValue={action(
                  (value) => (tiedoksiannettava.hetu = value)
                )}
              />
            </>
          )}
          <FormTextInput
            data-test-id={phoneTestId}
            labelText={t('puhelinnumero')}
            value={tiedoksiannettava.phone || ''}
            validate={commonPhoneValidator(t)}
            updateValue={action((value) => (tiedoksiannettava.phone = value))}
          />
          <FormTextInput
            data-test-id={emailTestId}
            labelText={t('sahkoposti')}
            value={tiedoksiannettava.email || ''}
            validate={commonEmailValidator(t)}
            updateValue={action((value) => (tiedoksiannettava.email = value))}
          />
          <FormTextInput
            data-test-id={katuosoiteTestId}
            labelText={t('katuosoite')}
            value={tiedoksiannettava.address.streetAddress}
            required
            validate={commonXssValidator(t)}
            updateValue={action(
              (value) => (tiedoksiannettava.address.streetAddress = value)
            )}
          />
          <FormTextInput
            data-test-id={postinumeroTestId}
            labelText={t('postinumero')}
            value={tiedoksiannettava.address.postalCode}
            required
            validate={commonXssValidator(t)}
            updateValue={action(
              (value) => (tiedoksiannettava.address.postalCode = value)
            )}
          />
          <FormTextInput
            data-test-id={postitoimipaikkaTestId}
            labelText={t('postitoimipaikka')}
            value={tiedoksiannettava.address.postOffice}
            required
            validate={commonXssValidator(t)}
            updateValue={action(
              (value) => (tiedoksiannettava.address.postOffice = value)
            )}
          />
          <FormCountryDropdown
            data-test-id={maaTestId}
            value={countryName}
            updateValue={action(
              (value) => (tiedoksiannettava.address.countryId = value)
            )}
          />
        </BlueBorderedContentBlock>
      )}
    </section>
  )
})

export default TiedoksiantoForm
