import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import EdunvalvonnanKulutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/EdunvalvonnanKulutForm'
import ElatusavutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/ElatusavutForm'
import ElinkustannuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/ElinkustannuksetForm'
import MuutMenotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/MuutMenotForm'
import PalvelumaksutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/PalvelumaksutForm'
import PankinPerimatMaksutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/PankinPerimatMaksutForm'
import TyonantajakulutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/TyonantajakulutForm'
import VelanhoitomenotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/VelanhoitomenotForm'
import VerotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/VerotForm'
import VuokratVastikkeetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/VuokratVastikkeetForm'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/menot/KayttovaratForm'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const MenotPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('menotHeading')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />

        <Paragraph>{t('menotInfo1')}</Paragraph>
        <Paragraph mt="s">{t('menotInfo2')}</Paragraph>
        <VahvistaEsitaytetytTiedot
          tiedot={[
            'kayttovarat',
            'elinkustannukset',
            'palvelumaksut',
            'vuokratVastikkeet',
            'elatusavut',
            'pankinPerimatMaksut',
            'verot',
            'tyonantajakulut',
            'velanhoitomenot',
            'edunvalvonnankulut',
            'muutMenot',
          ]}
          dataTestId={mkTulotMenotTestId('menotPage-isTarkistettu')}
        >
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          {!isPrincipalUnderaged && (
            <>
              <KayttovaratForm />
              <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            </>
          )}

          <ElinkustannuksetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <PalvelumaksutForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <VuokratVastikkeetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          {!isPrincipalUnderaged && (
            <>
              <ElatusavutForm />
              <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            </>
          )}

          <PankinPerimatMaksutForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <VerotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <TyonantajakulutForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <VelanhoitomenotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <EdunvalvonnanKulutForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <MuutMenotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </VahvistaEsitaytetytTiedot>
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default MenotPage
