import fp from 'lodash/fp'
import keyMirror from 'keymirror'

export const Catalog = keyMirror({
  VUOSI_2021: null,
  VUOSI_2024: null,
  VUOSI_2025: null,
})
export type CatalogId = (typeof Catalog)[keyof typeof Catalog]

export const BillingClass = keyMirror({
  MAKSUTON: null,
  MUU: null,
  VARALLISUUS_0_85000: null,
  VARALLISUUS_85001_170000: null,
  VARALLISUUS_170001_TAI_ENEMMAN: null,
})
export type BillingClassId = (typeof BillingClass)[keyof typeof BillingClass]

export const PerinnonjakoBillingClass = fp.pick(
  [
    'MAKSUTON',
    'VARALLISUUS_0_85000',
    'VARALLISUUS_85001_170000',
    'VARALLISUUS_170001_TAI_ENEMMAN',
  ],
  BillingClass
)

export const MyyntiBillingClass = fp.pick(['MAKSUTON', 'MUU'], BillingClass)

export const BillingAdditionalInfoType = keyMirror({
  KUOLINPESA_MAKSUVELVOLLINEN: null,
  PUOLET_PAAMIES_PUOLET_KUOLINPESA: null,
  EI_KUOLINPESAA: null,
  MUU_SELITE: null,
})
export type BillingAdditionalInfoTypeId =
  (typeof BillingAdditionalInfoType)[keyof typeof BillingAdditionalInfoType]

export const LupaRondoCustomerGroup = keyMirror({
  KUNNAT: null,
  KOTITALOUDET: null,
  ELINKEINOELAMA: null,
  VALTIO: null,
  MUUT: null,
  EI_VALITTU: null,
})
export type LupaRondoCustomerGroupId =
  (typeof LupaRondoCustomerGroup)[keyof typeof LupaRondoCustomerGroup]
