import {
  Block,
  Button,
  IconArrowLeft,
  IconArrowRight,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React, { useState } from 'react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { observer } from 'mobx-react'
import { mkAlasivuNavigationTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  currentNavigationSteps,
  resolveTilintarkastusNavigationPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useFormContext } from 'react-hook-form'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  getTilintarkastusStore,
  saveTiliAsDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import styled from 'styled-components'
import SaveDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/SaveDialog'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'

const FormNavigationBar = observer(
  ({ onErrorRef }: { onErrorRef: React.RefObject<HTMLDivElement> }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const navigate = useNavigate()
    const [isSaveDialogVisible, setSaveDialogVisible] = useState(false)
    const { handleSubmit } = useFormContext()

    const { excludeSteps, vaatimus } = getTilintarkastusStore()
    const openFrontPage = () =>
      navigate(getAsioiPathByPersonId(vaatimus.personId))

    const location = useLocation()
    const { previous, next } = currentNavigationSteps(excludeSteps, location)

    const onSaveAndExit = () => {
      void saveTiliAsDraft()
      setSaveDialogVisible(false)
      openFrontPage()
    }

    return (
      <Block
        mt={verticalMarginToken}
        data-test-id={mkAlasivuNavigationTestId('container')}
      >
        <SaveDialog
          visible={isSaveDialogVisible}
          onSave={onSaveAndExit}
          onClose={() => {
            setSaveDialogVisible(false)
          }}
        />
        <NavButtonContainer>
          <NavButton
            onClick={() => setSaveDialogVisible(true)}
            variant="secondary"
          >
            {t('tallennaJaPalaaEtusivulle')}
          </NavButton>

          <NavButtonGroupContainer>
            {previous && (
              <NavButton
                data-test-id={mkAlasivuNavigationTestId(
                  'previous-step-link-button'
                )}
                variant="secondary"
                icon={<IconArrowLeft />}
                onClick={() => {
                  void saveTiliAsDraft()
                  navigate(
                    resolveTilintarkastusNavigationPath(vaatimus, previous)
                  )
                }}
              >
                {t('previous')}
              </NavButton>
            )}
            <Block ml="xs" />
            {next && (
              <NavButton
                data-test-id={mkAlasivuNavigationTestId(
                  'next-step-link-button'
                )}
                iconRight={<IconArrowRight />}
                onClick={async () =>
                  handleSubmit(
                    () => {
                      void saveTiliAsDraft()
                      navigate(
                        resolveTilintarkastusNavigationPath(vaatimus, next)
                      )
                    },
                    () => {
                      setTimeout(() => onErrorRef?.current?.focus())
                    }
                  )()
                }
              >
                {t('next')}
              </NavButton>
            )}
          </NavButtonGroupContainer>
        </NavButtonContainer>
      </Block>
    )
  }
)

export default FormNavigationBar

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${suomifiDesignTokens.spacing.xs};
`
export const NavButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const NavButton = styled(Button)`
  padding: ${suomifiDesignTokens.spacing.xs};
  min-width: 80px;

  @media screen and ${device.tablet} {
    padding-inline: ${suomifiDesignTokens.spacing.m};
  }
`
