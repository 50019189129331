import React, { useEffect } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import PaamiesFrontPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiesFrontPage'
import LandingPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/LandingPage'
import MainFooter from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MainFooter'
import CookieInfoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/cookies/CookieInfoPage'
import { cookiePageUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import PageNotFound from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageNotFound'
import lupaAsiointiRoutes from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaAsiointiRoutes'
import { TilintarkastusAppRoutes } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/TilintarkastusAppRoutes'
import { ASIOINTI_MAIN_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'
import PaamiehenValintaPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiehenValintaPage'
import {
  initializeEvtvStore,
  useEvtvStore,
  valitseHenkiloByPersonId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { LoadingSpinner } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import {
  fetchDraftsIntoStore,
  useDraftStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import { TILINTARKASTUS_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useForm } from 'react-hook-form'

export const SelectedPaamiesRoutes: React.FC<{ redirect: string }> = observer(
  ({ redirect = '/' }) => {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const { user } = useAsiointiUserStore()
    if (!user) {
      navigate(redirect)
      return
    }

    const { personId } = useParams()

    const evtvStore = useEvtvStore()
    if (evtvStore.isInitialized) {
      const found = personId && valitseHenkiloByPersonId(personId)
      if (!found) {
        navigate(redirect)
        return
      }
    }
    useEffect(() => {
      if (!evtvStore.isInitialized) {
        void initializeEvtvStore()
      }
    }, [evtvStore.isInitialized])

    const draftStore = useDraftStore()
    useEffect(() => {
      if (!draftStore.isInitialized) {
        void fetchDraftsIntoStore()
      }
    }, [draftStore.isInitialized])

    if (!evtvStore.isInitialized || evtvStore.isLoading) {
      return (
        <CenteredWidthLimitedDiv paddingX paddingY>
          <LoadingSpinner text={t('ladataan')} textAlign="right" />
        </CenteredWidthLimitedDiv>
      )
    }

    return <Outlet />
  }
)

const AsiointiAppRoutes: React.FC = observer(() => {
  const { user } = useAsiointiUserStore()
  const forms = {
    [TILINTARKASTUS_ROUTES.ALOITUS]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.VARAT]: useForm({ shouldFocusError: false }),
    [TILINTARKASTUS_ROUTES.VELAT]: useForm({ shouldFocusError: false }),
    [TILINTARKASTUS_ROUTES.TULOT]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.MENOT]: useForm({
      shouldFocusError: false,
    }),

    [TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.KAYTTOVARAT]: useForm({ shouldFocusError: false }),
    [TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.PALKKIO]: useForm({ shouldFocusError: false }),
    [TILINTARKASTUS_ROUTES.LISATIEDOT]: useForm({ shouldFocusError: false }),

    [TILINTARKASTUS_ROUTES.YHTEYSTIEDOT]: useForm({
      shouldFocusError: false,
    }),
    [TILINTARKASTUS_ROUTES.VALTAKIRJA]: useForm({ shouldFocusError: false }),
    [TILINTARKASTUS_ROUTES.YHTEENVETO]: useForm(), // sivulla ei käytetä virheyhteenvetoa, joten focus ok
  }
  return (
    <Routes>
      <Route Component={Layout}>
        <Route
          path={ASIOINTI_MAIN_ROUTES.JUURI}
          element={user ? <PaamiehenValintaPage /> : <LandingPage />}
        />
        <Route
          element={
            <SelectedPaamiesRoutes redirect={ASIOINTI_MAIN_ROUTES.JUURI} />
          }
        >
          <Route
            path={ASIOINTI_MAIN_ROUTES.ASIOI}
            Component={PaamiesFrontPage}
          />
        </Route>
        <Route path={cookiePageUrl} Component={CookieInfoPage} />
        {lupaAsiointiRoutes}
        {TilintarkastusAppRoutes(forms)}
        <Route path="*" Component={PageNotFound} />
      </Route>
    </Routes>
  )
})

const Layout: React.FC = () => {
  const { pathname } = useLocation()
  const isFooterVisible = !pathname.startsWith(ASIOINTI_MAIN_ROUTES.LUVAT)

  return (
    <LayoutGrid>
      <main>
        <Outlet />
      </main>
      {isFooterVisible && <MainFooter />}
    </LayoutGrid>
  )
}

const LayoutGrid = styled.div`
  grid-area: content;
  display: grid;
  grid-template-rows: 1fr auto;
`

export default AsiointiAppRoutes
