import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTilirivit,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontHuoneisto } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonPercentageWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import styled from 'styled-components'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Kayttotarkoitus } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const HuoneistotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const omaisuus = getTilirivit()

  return (
    <section data-test-id={mkOmaisuusTestId('huoneistot-form')}>
      <Heading variant="h3" mb="xs">
        {t('huoneistotHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaHuoneistot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        state={omaisuus.varat.huoneistot}
        ModalContentComponent={HuoneistoModal}
        ExpanderComponent={HuoneistoListItem}
        dataTestIdPrefix={mkOmaisuusTestId('huoneistot-form')}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            tiliointinumero: '',
            tyyppi:
              AsiointiLedgerAccountAssetsType.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT,
            kayttotarkoitus: undefined,
            osakkeidenNumerot: '',
            yhtionNimi: '',
            tyhjillaanSelvitys: '',
            omistusosuusProsentteina: undefined,
            arvoTilikaudenAlussaEur: undefined,
            arvoTilikaudenLopussaEur: undefined,
            asiakirjat: [],
            vuokrasopimukset: [],
          })
        }
        translationPrefix="huoneistot"
        stateSubmitAdapter={({
          vuokrasopimukset,
          tyhjillaanSelvitys,
          ...rest
        }: FrontHuoneisto) => {
          if (rest.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN) {
            return { ...rest, vuokrasopimukset: [], tyhjillaanSelvitys }
          } else if (rest.kayttotarkoitus === Kayttotarkoitus.VUOKRATTUNA) {
            return { ...rest, vuokrasopimukset }
          } else {
            return { ...rest, vuokrasopimukset: [] }
          }
        }}
        tiliLomakeKentta={TiliLomakeKentta.huoneistot}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const HuoneistoModal: FC<FormModalProps<FrontHuoneisto>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const { vaatimus: evtv } = getTilintarkastusStore()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)

    const kayttotarkoitustyypit = useMemo(
      () =>
        Object.values(Kayttotarkoitus).map((value) => ({
          name: t(`kayttotarkoitus_${value}`),
          value,
        })),
      [t]
    )

    const translations =
      translationsByType[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-yhtionNimi')}
          labelText={t('yhtionNimi')}
          value={entry.yhtionNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, yhtionNimi: value })}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-osakkeidenNumerot')}
          labelText={t('osakkeidenNumerot')}
          value={entry.osakkeidenNumerot}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, osakkeidenNumerot: value })
          }
        />
        <FormNumberInput
          data-test-id={createDataTestId('modal-omistusosuusProsentteina')}
          labelText={t('omistusOsuusProsentteina')}
          required
          digitMode="five"
          validate={commonPercentageWithDecimalsOrUndefinedValidator(t, 'two')}
          value={entry.omistusosuusProsentteina}
          updateValue={(value) =>
            setEntry({
              ...entry,
              omistusosuusProsentteina: value,
            })
          }
        />

        {!isOmaisuusluettelo() && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          hintText={
            isOmaisuusluettelo()
              ? t(translations.kaypaArvoHintText, { alkupvm })
              : t(translations.kaypaArvoHintText)
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormDropdown
          labelText={t('kayttotarkoitus')}
          data-test-id={createDataTestId('modal-kayttotarkoitus')}
          value={entry.kayttotarkoitus}
          items={kayttotarkoitustyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              kayttotarkoitus: value as Kayttotarkoitus,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        {entry.kayttotarkoitus === Kayttotarkoitus.OMASSA_KAYTOSSA && (
          <>
            <InlineAlert>
              <Text smallScreen>{t('omassaKaytossaInfoHeading')}</Text>
              <Block mt={'m'} />
              <List>
                <li>{t('omassaKaytossaInfoItem1')}</li>
                <li>{t('omassaKaytossaInfoItem2')}</li>
                <li>{t('omassaKaytossaInfoItem3')}</li>
              </List>
            </InlineAlert>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN && (
          <>
            <FormTextInput
              data-test-id={createDataTestId('modal-tyhjana')}
              labelText={t('miksiTyhjillaan')}
              hintText={t('miksiTyhjillaanSelvitys')}
              required
              characterLimit={50}
              validate={commonXssValidator(t)}
              value={entry.tyhjillaanSelvitys}
              updateValue={(value) => {
                setEntry({
                  ...entry,
                  tyhjillaanSelvitys: value,
                })
              }}
            ></FormTextInput>
          </>
        )}

        {entry.kayttotarkoitus === Kayttotarkoitus.VUOKRATTUNA && (
          <>
            <FormAttachmentFileBox
              observableAttachments={entry.vuokrasopimukset}
              asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
              data-test-id={createDataTestId('modal-vuokrasopimus')}
              text={t(translations.vuokrattunaInfo)}
              title={t('vuokrasopimukset')}
            />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translations.asiakirjatInfo)}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const HuoneistoListItem: FC<FormElementProps<FrontHuoneisto>> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    const translations =
      translationsByType[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    const omistusosuusProsentti =
      entry.omistusosuusProsentteina !== undefined
        ? `${formatNumberWithComma(entry.omistusosuusProsentteina)} %`
        : ''

    const arvoLopussaEur =
      entry.arvoTilikaudenLopussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
        : undefined
    const arvoAlussaEur =
      entry.arvoTilikaudenAlussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
        : ''

    return (
      <FormListInputExpander
        heading={entry.yhtionNimi}
        headingRight={arvoLopussaEur}
        subHeading={entry.osakkeidenNumerot}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        pakollinenTietoPuuttuu={entry.arvoTilikaudenLopussaEur === undefined}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        <Heading variant="h5">{t('osakkeidenNumerot')}</Heading>
        <Text>{entry.osakkeidenNumerot}</Text>
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('kayttotarkoitus')}</Heading>
        <Text>{t(`kayttotarkoitus_${entry.kayttotarkoitus}`)}</Text>
        {entry.tyhjillaanSelvitys &&
          entry.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN && (
            <Paragraph mb={verticalMarginToken}>
              {entry.tyhjillaanSelvitys}
            </Paragraph>
          )}

        <Heading variant="h5">{t('omistusOsuusProsentteina')}</Heading>
        <Text>{omistusosuusProsentti}</Text>
        <Block mt={verticalMarginToken} />

        {!isOmaisuusluettelo() && (
          <>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{arvoAlussaEur}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t(translations.arvoLopussa)}
          content={arvoLopussaEur}
        />

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        {entry.vuokrasopimukset.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('vuokrasopimukset')}</Heading>
            <AttachmentList attachments={entry.vuokrasopimukset} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

const translationsByType = {
  OMAISUUSLUETTELO: {
    arvoLopussa: 'arvoEuroina',
    asiakirjatInfo: 'asiakirjaHuoneistotInfo',
    vuokrattunaInfo: 'asiakirjaVuokrasopimusHuoneisto',
    kaypaArvoHintText: 'kaypaArvoTehtavanAlussa',
  },
  TILI: {
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    asiakirjatInfo: 'huoneistotAsiakirjaInfo',
    vuokrattunaInfo: 'huoneistotVuokrasopimuksetInfo',
    kaypaArvoHintText: 'kaypaArvoHintText',
  },
}

const List = styled.ul`
  list-style: disc;
  margin-left: ${suomifiDesignTokens.spacing.m};

  li {
    padding-left: ${suomifiDesignTokens.spacing.s};
  }
`
export default HuoneistotForm
