import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontVuokratulo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const VuokratulotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const tilirivit = getTilirivit()

  const mkNewElement = (): FrontVuokratulo =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountIncomeType.VUOKRATULOT,
      nimi: '',
      tunnus: '',
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('vuokratulot-form')}>
      <Heading variant="h3" mb="xs">
        {t('vuokratulotHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaVuokratulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={VuokratuloListItem}
        state={tilirivit.tulot.vuokratulot}
        dataTestIdPrefix={mkTulotMenotTestId('vuokratulot-form')}
        ModalContentComponent={VuokratuloModal}
        newEntry={mkNewElement}
        translationPrefix="vuokratulot"
        tiliLomakeKentta={TiliLomakeKentta.vuokratulot}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const VuokratuloModal: FC<FormModalProps<FrontVuokratulo>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-nimi')}
          labelText={t('vuokratunOmaisuudenTyyppi')}
          hintText={t('esimerkiksiHuoneisto')}
          value={entry.nimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, nimi: value })}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-tunnus')}
          labelText={t('kohteenTarkempiKuvaus')}
          hintText={t('esimerkkiKohteenKuvaus')}
          value={entry.tunnus}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, tunnus: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-tulotYhteensa')}
          labelText={t('tulotTilikaudenAikanaYhteensaEuroina')}
          hintText={t('vuokratulotYhteensaHintText')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const VuokratuloListItem: FC<FormElementProps<FrontVuokratulo>> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'
    const [t] = useTranslation()

    const yhteensaEur =
      entry.yhteensa !== undefined
        ? `${formatNumberWithComma(entry.yhteensa)} €`
        : undefined

    return (
      <FormListInputExpander
        heading={entry.nimi}
        headingRight={yhteensaEur}
        pakollinenTietoPuuttuu={entry.yhteensa === undefined}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        <Heading variant="h5">{t('kohteenTarkempiKuvaus')}</Heading>
        <Paragraph mb={verticalMarginToken}>{entry.tunnus}</Paragraph>
        <Block mt={verticalMarginToken} />

        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t('tulotTilikaudenAikanaYhteensaEuroina')}
          content={yhteensaEur}
        />
      </FormListInputExpander>
    )
  }
)

export default VuokratulotForm
