import React from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconCheckCircleFilled,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import {
  LupaApplicationOtherView,
  pathByViewId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { mkHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import {
  BulletList,
  focusOutline,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  getLupaFeedbackUrl,
  kasittelyAjatUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'
import { getValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import {
  getStore,
  initAsiointiStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'

const DonePage: React.FC = observer(() => {
  const [t, i18n] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const navigate = useNavigate()
  const henkilo = getValittuHenkilo()
  const { user, lang } = useAsiointiUserStore()
  const asiointiStore = getStore()

  const openSummary = () =>
    navigate(pathByViewId[LupaApplicationOtherView.PRINTABLE_SUMMARY])
  const openFrontPage = async () => {
    if (user) {
      await initAsiointiStore(
        user,
        lang,
        asiointiStore.countries,
        asiointiStore.productCatalog
      )
    }
    navigate(getAsioiPathByPersonId(henkilo?.personId))
  }
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <ResponsiveBorderedContentBlock
      data-test-id={mkHakemusTestId(LupaApplicationOtherView.DONE)}
    >
      <ValmisBlock mb={verticalMarginToken}>
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis', { context: 'hakemus' })}
      </ValmisBlock>
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t('kiitosHakemusOnLahetetty')}
      </FocusableHeading>
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('mitaSeuraavaksiTapahtuu')}
        </Heading>
        <BulletList>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOttaaHakemuksenKasittelyyn')}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOnSinuunYhteydessaJosTarvitaanLisatietoja')}{' '}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaTekeePaatoksenJaSeLahetetaan')}{' '}
            </Text>
          </li>
        </BulletList>
      </Block>
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('kasittelyaika')}
        </Heading>
        <Block mt="xs">
          <ExternalLink
            href={kasittelyAjatUrl(
              i18n.language as HolhousAsiointiLanguageCode
            )}
            variant="accent"
            underline="initial"
            smallScreen={!isTablet}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('tarkistaHenkiloasiakkaidenKasittelyajatDvvnSivuilta')}
          </ExternalLink>
        </Block>
      </Block>
      <ButtonBlock variant="section" mt={verticalMarginToken}>
        <Button variant="secondary" onClick={openSummary} role="link">
          {t('avaaHakemus')}
        </Button>
        <Button onClick={openFrontPage} role="link">
          {t('siirryEtusivulle')}
        </Button>
      </ButtonBlock>
      <DividerLine mt={verticalMarginToken} />
      <Block mt={verticalMarginToken}>
        <ExternalLink
          href={getLupaFeedbackUrl(lang)}
          variant="accent"
          underline="initial"
          smallScreen={!isTablet}
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('annaPalautettaPalvelusta')}
        </ExternalLink>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

const ValmisBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${suomifiDesignTokens.spacing.insetL};
`

const FocusableHeading = styled(Heading)`
  ${focusOutline};
`

const ButtonBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${suomifiDesignTokens.spacing.xs};
`

export default DonePage
