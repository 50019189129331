import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Button,
  Heading,
  IconPrint,
  Link,
  Paragraph,
} from 'suomifi-ui-components'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Perustiedot'
import Yhteenveto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Yhteenveto'
import { getWindowNonceId } from 'common/src/vtj/browser/browser-nonce'
import { useReactToPrint } from 'react-to-print'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import styled from 'styled-components'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'

const TulostusPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const printComponentRef = useRef<HTMLElement>(null)
  const { vaatimus } = getTilintarkastusStore()

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle:
      '@page { size: auto; margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }',
    nonce: getWindowNonceId(),
  })
  const headingTranslationString = {
    OMAISUUSLUETTELO: 'omaisuusluetteloYhteenveto',
    PAATOSTILI: 'paatostiliYhteenveto',
    VUOSITILI: 'vuositiliYhteenveto',
  }

  const frontPageUrl = getAsioiPathByPersonId(vaatimus.personId)
  const ref = useAutoFocus<HTMLDivElement>()

  return (
    <>
      <Heading variant="h1" mb={verticalMarginToken} ref={ref} tabIndex={-1}>
        {t('printableYhteenvetoHeading')}
      </Heading>
      <PrintButton onClick={handlePrint} icon={<IconPrint />}>
        {t('tulostaYhteenveto')}
      </PrintButton>
      <Paragraph mt={verticalMarginToken} mb={verticalMarginToken}>
        {t('voitJatkaaAsiointiaJa')}{' '}
        <Link href={frontPageUrl} underline="initial">
          {t('palataEtusivulle')}
        </Link>
      </Paragraph>
      <ResponsiveBorderedContentBlock
        data-test-id={mkValmisTestId('container')}
        forwardedRef={printComponentRef}
      >
        <Heading variant="h2">
          {t(headingTranslationString[vaatimus.asiaType])}
        </Heading>

        <DividerLine mt={verticalMarginToken} />

        <Perustiedot showValtakirja />
        <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
        <Yhteenveto />
        <DividerLine mb={verticalMarginToken} />
      </ResponsiveBorderedContentBlock>
    </>
  )
})

export default TulostusPage

const PrintButton = styled(Button)`
  align-self: baseline;
`
