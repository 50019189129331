import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getAddressAsArray } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliPersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'

const getOmatTiedotContent = (
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      edunvalvoja: { lastname, firstnames, address, email, phone },
    },
  } = getTilintarkastusStore()
  return [
    {
      rows: [`${firstnames} ${lastname}`, ...getAddressAsArray(address, lang)],
    },
    {
      heading: t('sahkoposti'),
      rows: [email].filter(Boolean),
    },
    {
      heading: t('puhelinnumero'),
      rows: [phone].filter(Boolean),
    },
  ]
}

const getTiedoksiantoContent = (
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const {
    lomake: { tiedoksiannettava, lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()

  if (!lomakkeelleValitutTiedot.tiedoksiannettava) {
    return [
      {
        heading: t('oletkoKuolinpesanAinoaOsakas'),
        rows: [t('olen')],
      },
    ]
  }

  const rows = []
  if (tiedoksiannettava) {
    if (tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YKSITYISHENKILO) {
      rows.push(
        ...[
          [
            tiedoksiannettava.firstnames,
            tiedoksiannettava.lastname,
            tiedoksiannettava.hetu ? `(${tiedoksiannettava.hetu})` : '',
          ]
            .filter(Boolean)
            .join(' ') || undefined,
          ...getAddressAsArray(tiedoksiannettava.address, lang),
        ].filter(Boolean)
      )
    } else if (tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YRITYS) {
      rows.push(
        ...[
          tiedoksiannettava.businessName,
          tiedoksiannettava.businessId,
          ...getAddressAsArray(tiedoksiannettava.address, lang),
        ].filter(Boolean)
      )
    }
    return [
      { rows },
      {
        heading: t('sahkoposti'),
        rows: [tiedoksiannettava.email].filter(Boolean),
      },
      {
        heading: t('puhelinnumero'),
        rows: [tiedoksiannettava.phone].filter(Boolean),
      },
    ]
  }
  return []
}

const Yhteystiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const lang = useAsiointiUserStore().lang
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    lomake: { tiedoksiannettava },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkYhteenvetoTestId('yhteystiedot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('omatYhteystiedot')}
      </Heading>
      <YhteenvetoGroups groups={getOmatTiedotContent(t, lang)} />
      <DividerLine mb={verticalMarginToken} />
      {tiedoksiannettava && (
        <>
          <Heading variant="h3" mb={verticalMarginToken}>
            {t('tiedoksianto')}
          </Heading>
          <YhteenvetoGroups groups={getTiedoksiantoContent(t, lang)} />
        </>
      )}
    </section>
  )
})

export default Yhteystiedot
