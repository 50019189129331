import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import {
  mkOmaisuusTestId,
  mkVarat,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import PankkiTalletuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/PankkiTalletuksetForm'
import ArvopaperitForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/ArvopaperitForm'
import KuolinpesaOsakkuudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/KuolinpesaOsakkuudetForm'
import OsuudetYhtioissaYrityksissaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/OsuudetYhtioissaYrityksissaForm'
import HuoneistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/HuoneistotForm'
import KiinteistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/KiinteistotForm'
import IrtaimistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/IrtaimistotForm'
import SaatavatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/SaatavatForm'
import MuutVaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/varat/MuutVaratForm'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'

const VaratPage: React.FC<{
  form: UseFormReturn<FieldValues, unknown>
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <section data-test-id={mkVarat()}>
          <PageStepHeading heading={t('varat')} steps={TILINTARKASTUS_STEPS} />
          <FormErrorSummary ref={onErrorRef} />

          <Paragraph>
            {t(isOmaisuusluettelo() ? 'varatOlInfo1' : 'varatTilikausiInfo1')}
          </Paragraph>
          {isOmaisuusluettelo() && (
            <>
              <Paragraph mt="s">{t('varatOlInfo2')}</Paragraph>
              <Paragraph mt="s">{t('varatOlInfo3')}</Paragraph>
            </>
          )}
          <VahvistaEsitaytetytTiedot
            tiedot={[
              'talletukset',
              'arvopaperit',
              'osuudetHenkiloYhtiossaJaYrityksissa',
              'huoneistot',
              'kiinteistot',
              'irtaimistot',
              'saatavat',
              'muutVarat',
              'osuudetKuolinpesissa',
            ]}
            dataTestId={mkOmaisuusTestId('varatPage-isTarkistettu')}
          >
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <PankkiTalletuksetForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <ArvopaperitForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <OsuudetYhtioissaYrityksissaForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <HuoneistotForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <KiinteistotForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <IrtaimistotForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <SaatavatForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <MuutVaratForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <KuolinpesaOsakkuudetForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </VahvistaEsitaytetytTiedot>
        </section>
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default VaratPage
