import { Block, Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  mkAsuminenFormTestId,
  mkToimintakykyAsuminenTestId,
  mkYleiskatsausTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import styled from 'styled-components'
import { AsuinpaikkaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/AsuinpaikkaForm'
import { HoitosuunnitelmaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/HoitosuunnitelmaForm'
import { ToimintakykyForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/ToimintakykyForm'
import AlaikainenAsuminenForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/AlaikainenAsuminenForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'

const ToimintakykyAsuminenPage: React.FC<{ form: TilintarkastusForm }> =
  observer(({ form }) => {
    const [t] = useTranslation()
    const {
      vaatimus: { isPrincipalUnderaged, asiaType },
    } = getTilintarkastusStore()
    const onErrorRef = useRef<HTMLDivElement>(null)

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkToimintakykyAsuminenTestId('container')}
        >
          <PageStepHeading
            heading={
              isPrincipalUnderaged
                ? t('asuminenHeading')
                : t('toimintakykyJaAsuminen')
            }
            steps={TILINTARKASTUS_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />
          <ParagraphWithLineBreaks>
            {isPrincipalUnderaged
              ? t(translations[asiaType].ohje.alaikainen)
              : t(translations[asiaType].ohje.taysiIkainen)}
          </ParagraphWithLineBreaks>
          <Paragraph mt="m">
            {t(translations[asiaType].pakollinendisclaimer)}
          </Paragraph>
          <VahvistaEsitaytetytTiedot
            tiedot={['asuinpaikka', 'huoltajalla', 'toimintakyky']}
            dataTestId={mkYleiskatsausTestId('asuminenPage-isTarkistettu')}
          >
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            {isPrincipalUnderaged ? (
              <AlaikainenAsuminenForm />
            ) : (
              <section data-test-id={mkAsuminenFormTestId('taysikainen')}>
                <AsuinpaikkaForm />
                <Block mt={verticalMarginToken} />
                <HoitosuunnitelmaForm />
                <DividerLine
                  mb={verticalMarginToken}
                  mt={verticalMarginToken}
                />
                <ToimintakykyForm />
              </section>
            )}
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </VahvistaEsitaytetytTiedot>

          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    )
  })

const ParagraphWithLineBreaks = styled(Paragraph)`
  white-space: pre-line;
`
export default ToimintakykyAsuminenPage

const translations = {
  OMAISUUSLUETTELO: {
    ohje: {
      taysiIkainen: 'ohjeOmaisuusluetteloAsuinpaikat',
      alaikainen: 'ohjeOmaisuusluetteloAsuinpaikat_ALAIKAINEN',
    },
    pakollinendisclaimer: '',
  },
  VUOSITILI: {
    ohje: {
      taysiIkainen: 'ohjeTilikausiAsuinpaikat',
      alaikainen: 'ohjeTilikausiAsuinpaikat_ALAIKAINEN',
    },
    pakollinendisclaimer: 'vuositiliKaikkiPakollista',
  },
  PAATOSTILI: {
    ohje: {
      taysiIkainen: 'ohjeTilikausiAsuinpaikat',
      alaikainen: 'ohjeTilikausiAsuinpaikat_ALAIKAINEN',
    },
    pakollinendisclaimer: 'paatostiliKaikkiPakollista',
  },
}
