import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Block,
  Heading,
  Button,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { mkHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import {
  LupaApplicationOtherView,
  pathByViewId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  ResponsiveBorderedContentBlock,
  focusOutline,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import {
  getSortedSummaryApplications,
  SummaryApplication,
  SummaryPersonList,
  SummaryPerustiedot,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/summary/SummaryPage'
import { getWindowNonceId } from 'common/src/vtj/browser/browser-nonce'
import { AsiointiApplicationRole } from 'lupa-backend/src/vtj/asiointi/application/asiointi-application.type'

const PrintableSummaryPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const printComponentRef = useRef<HTMLElement>(null)

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle:
      '@page { size: auto; margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } a {pointer-events: none; text-decoration: none;}}',
    nonce: getWindowNonceId(),
  })

  return (
    <SummaryContentBlock
      data-test-id={mkHakemusTestId(LupaApplicationOtherView.PRINTABLE_SUMMARY)}
      forwardedRef={printComponentRef}
    >
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={useAutoFocus<HTMLHeadingElement>()}
        tabIndex={-1}
      >
        {t('lupahakemuksenYhteenveto')}
      </FocusableHeading>

      <Block mt="m" />

      <Buttons onPrint={handlePrint} />

      <Block mt="m" />

      <Heading variant="h3">{t('hakijat')}</Heading>
      <Block mt="s" />
      <SummaryPersonList
        roles={[
          AsiointiApplicationRole.HAKIJA,
          AsiointiApplicationRole.ASIAMIES,
        ]}
      />

      <Block mt="m" />

      <Heading variant="h3">{t('paamiehet')}</Heading>
      <Block mt="s" />
      <SummaryPersonList roles={[AsiointiApplicationRole.PAAMIES]} />

      <Block mt="m" />

      <Heading variant="h3">{t('hakemuksenPerustiedot')}</Heading>
      <Block mt="s" />
      <SummaryPerustiedot />

      {getSortedSummaryApplications().map((application, index) => (
        <React.Fragment key={application.typeId}>
          <Block mt="m" />
          <Heading variant="h3">
            {t(`asiointiLupaType-${application.typeId}`)}
          </Heading>
          <Block mt="s" />
          <SummaryApplication application={application} index={index} />
        </React.Fragment>
      ))}

      <NonPrintableBlock mt="m" />

      <Buttons onPrint={handlePrint} />
    </SummaryContentBlock>
  )
})

export default PrintableSummaryPage

const Buttons: React.FC<{ onPrint: () => unknown }> = ({ onPrint }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()

  return (
    <ButtonRow>
      <Button onClick={onPrint}>{t('tulostaHakemus')}</Button>
      <Button
        variant="secondary"
        onClick={() => navigate(pathByViewId[LupaApplicationOtherView.DONE])}
        role="link"
      >
        {t('takaisin')}
      </Button>
    </ButtonRow>
  )
}

const SummaryContentBlock = styled(ResponsiveBorderedContentBlock)`
  @media print {
    padding: 0;
    border: none;
  }
`

const FocusableHeading = styled(Heading)`
  ${focusOutline};
`

const ButtonRow = styled(Block)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${suomifiDesignTokens.spacing.xs};

  @media print {
    display: none;
  }
`

const NonPrintableBlock = styled(Block)`
  @media print {
    display: none;
  }
`
