import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getKayttovaratContent = (
  tili: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const { paamiehenOmaisuus } = tili

  const content: YhteenvetoGroupProps[] = []

  if (paamiehenOmaisuus.kayttovarakaytanto) {
    content.push({
      heading: t('mitenKayttovarojaOnHenkilolleAnnettu'),
      rows: [paamiehenOmaisuus.kayttovarakaytanto],
    })
  }

  return content
}

const Kayttovarat: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('kayttovarat-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('kayttovaratHeading')}
      </Heading>
      <YhteenvetoGroups groups={getKayttovaratContent(tili, t)} />
    </section>
  )
})

export default Kayttovarat
