import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { BlueBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEmailValidator,
  commonPhoneValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormCountryDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCountryDropdown'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { mkOmatTiedotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/country.store'
import { COUNTRY_CODE_FINLAND } from 'asia-common/src/vtj/country/country.util'
import { action } from 'mobx'

const OmatTiedotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { lang } = useAsiointiUserStore()

  const {
    lomake: { edunvalvoja },
  } = getTilintarkastusStore()

  const countryId = edunvalvoja.address.countryId || COUNTRY_CODE_FINLAND
  const countryName = getCountryById(countryId)?.shortName[lang]

  return (
    <section data-test-id={mkOmatTiedotTestId('container')}>
      <Heading variant="h3" mb="s">
        {t('omatYhteystiedot')}
      </Heading>
      <Paragraph>{t('omaisuusluetteloYhteystiedotInfo2')}</Paragraph>

      <Block mt={verticalMarginToken} />
      <BlueBorderedContentBlock padding="m">
        <Paragraph mb="xxs">
          <Text smallScreen variant="bold">
            {t('etunimet')}
          </Text>
        </Paragraph>
        <Paragraph mb="m">
          <Text smallScreen data-test-id={mkOmatTiedotTestId('etunimet')}>
            {edunvalvoja.firstnames}
          </Text>
        </Paragraph>
        <Paragraph mb="xxs">
          <Text smallScreen variant="bold">
            {t('sukunimi')}
          </Text>
        </Paragraph>
        <Paragraph mb="m">
          <Text smallScreen data-test-id={mkOmatTiedotTestId('sukunimi')}>
            {edunvalvoja.lastname}
          </Text>
        </Paragraph>

        <FormTextInput
          data-test-id={mkOmatTiedotTestId('puhelin')}
          labelText={t('puhelinnumero')}
          value={edunvalvoja.phone}
          validate={commonPhoneValidator(t)}
          updateValue={action((phone) => (edunvalvoja.phone = phone))}
          autoComplete="tel"
        />
        <FormTextInput
          data-test-id={mkOmatTiedotTestId('email')}
          labelText={t('sahkoposti')}
          value={edunvalvoja.email}
          validate={commonEmailValidator(t)}
          updateValue={action((email) => (edunvalvoja.email = email))}
          autoComplete="email"
          type="email"
        />
        <FormTextInput
          data-test-id={mkOmatTiedotTestId('katuosoite')}
          labelText={t('katuosoite')}
          value={edunvalvoja.address.streetAddress}
          required
          validate={commonXssValidator(t)}
          updateValue={action(
            (streetAddress) =>
              (edunvalvoja.address.streetAddress = streetAddress)
          )}
          autoComplete="street-address"
        />
        <FormTextInput
          data-test-id={mkOmatTiedotTestId('postinumero')}
          labelText={t('postinumero')}
          value={edunvalvoja.address.postalCode}
          required
          validate={commonXssValidator(t)}
          updateValue={action(
            (postalCode) => (edunvalvoja.address.postalCode = postalCode)
          )}
          autoComplete="postal-code"
        />
        <FormTextInput
          data-test-id={mkOmatTiedotTestId('postitoimipaikka')}
          labelText={t('postitoimipaikka')}
          value={edunvalvoja.address.postOffice}
          required
          validate={commonXssValidator(t)}
          updateValue={action(
            (postOffice) => (edunvalvoja.address.postOffice = postOffice)
          )}
          autoComplete="address-level2"
        />
        <FormCountryDropdown
          data-test-id={mkOmatTiedotTestId('maa')}
          value={countryName}
          updateValue={action(
            (countryId) => (edunvalvoja.address.countryId = countryId)
          )}
        />
      </BlueBorderedContentBlock>
    </section>
  )
})

export default OmatTiedotForm
