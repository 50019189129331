export enum ASIOINTI_AUTHENTICATION_REQUIRED_PATH_PREFIX {
  LUVAT = '/luvat',
  ASIOI = '/asioi',
}

export enum ASIOINTI_MAIN_ROUTES {
  JUURI = '/',
  ASIOI = ASIOINTI_AUTHENTICATION_REQUIRED_PATH_PREFIX.ASIOI + '/:personId',
  LUVAT = ASIOINTI_AUTHENTICATION_REQUIRED_PATH_PREFIX.LUVAT,
}

export const getAsioiPathByPersonId = (
  personId: string | undefined
): string => {
  if (personId) {
    return ASIOINTI_MAIN_ROUTES.ASIOI.replace(':personId', personId)
  } else {
    return ASIOINTI_MAIN_ROUTES.JUURI
  }
}
